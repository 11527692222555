import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import AttachmentList from "../../../components/formComponents/attachmentList";
import PuppiesList from "../../../components/formComponents/puppiesList";
import VerificationsList from "../../../components/formComponents/verificationsList";

// Routes
import {
  acceptTempLitter,
  deleteLitter,
  deleteTempLitter,
} from "../../../api/adminRoutes";

// MUI
import {
  Grid,
  TextField,
  Divider,
  Typography,
  Autocomplete,
  ListItem,
  List,
  ListItemText,
  Button,
  ListItemIcon,
  ListItemButton,
  Alert,
  AlertTitle,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Add, Launch } from "@mui/icons-material";
import ParentAutocomplete from "../../../components/formComponents/parentAutocomplete";
import { useTranslation } from "react-i18next";
import DogAutocomplete from "../../../components/formComponents/dogAutocomplete";

import { accessTokenState, userState } from "../../../recoil/globalStates";
import {
  adminAllOrgsState,
  adminAllUsersState,
  adminDogsByGenderState,
  adminGetBreederByIdState,
  adminTempDogsState,
} from "../../../recoil/adminStates";
import { constSelector, useRecoilValue } from "recoil";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import FormHeader from "../../../components/adminDashboardComponents/formHeader";
import AcceptButton from "../../../components/adminDashboardComponents/formRegistrationAcceptButton";
import JsonPreviewWidget from "../../../components/adminDashboardComponents/jsonPreviewWidget";
import FormButtons from "../../../components/adminDashboardComponents/formButtons";
import { useAuth0 } from "@auth0/auth0-react";

export default function LitterForm({
  litter: initialLitter,
  formType,
  action,
  emitHandleSubmit,
  handleModalClose,
}) {
  const { isAuthenticated } = useAuth0();
  const currentUser = useRecoilValue(
    isAuthenticated ? userState : constSelector(null)
  );

  const { t } = useTranslation();
  const accessToken = useRecoilValue(accessTokenState);

  const users = useRecoilValue(adminAllUsersState);
  const femaleDogs = useRecoilValue(adminDogsByGenderState("female"));
  const maleDogs = useRecoilValue(adminDogsByGenderState("male"));

  const tempdogs = useRecoilValue(adminTempDogsState);

  const {
    control,
    methods,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm();

  const { append, remove } = useFieldArray({
    control,
    name: "puppies",
  });

  const formdata = watch();
  const breeder = formdata.breeder;

  const [litter, setLitter] = useState(initialLitter || { puppies: [] });

  // set the puppies array from the initial litter since useFieldArray doesnt allow
  // setting a default and useForm defaults dont allow variables as defaults
  useEffect(() => {
    setValue("puppies", litter?.puppies);
  }, [litter]);

  const [selectedBreeder, setSelectedBreeder] = useState(
    useRecoilValue(adminGetBreederByIdState(litter.breeder))
  );
  const [selectedSire, setSelectedSire] = useState(litter.sire);
  const [selectedDam, setSelectedDam] = useState(litter.dam);
  const [selectedPuppy, setSelectedPuppy] = useState(null);

  const organizations = useRecoilValue(adminAllOrgsState);
  const [litterOrgs, setLitterOrgs] = useState(litter.organizations || []);

  // Handle form submit, call the parent components handleSubmit function that send the document to API patch
  const onSubmit = (data) => {
    // map each object as _id before sending it to API
    if (data.puppies) {
      data.puppies = data.puppies.map((pup) => pup._id);
    }
    data.breeder = data.breeder._id;
    data.sire = data.sire?._id;
    data.dam = data.dam?._id;
    emitHandleSubmit(accessToken, data, litter?._id);
  };

  // Handle deletion, prompt for confirm before the api call is done,
  // after removal, redirect user to listing
  const handleDelete = () => {
    const confirmation = confirm(
      "Are you sure you want to delete this litter? This process can NOT be undone!"
    );
    if (!confirmation) alert("Cancelled deletion");

    const deletionRoutes = {
      tempLitter: deleteTempLitter,
      litter: deleteLitter,
    };
    const route = deletionRoutes[formType];

    if (!route) throw { error: true, msg: "Error in LitterForm Formtype" };

    route(litter._id, { accessToken }).then((response) => {
      if (response.error) {
        setSnackbarState({
          message: response.message,
          severity: "error",
          open: true,
        });
      } else {
        alert(`${formType} ${response._id} has been removed.`);
        window.location.replace(`/admin/all${formType}s`);
      }
    });
  };

  const handleAccept = () => {
    if (
      confirm(
        "Are you sure you want to accept this litter? Litter is going to be published in the public database."
      )
    ) {
      acceptTempLitter(litter._id, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          alert("Litter has been accepted and transferred to public database.");
          window.location.replace(`/admin/all${formType}s`);
        }
      });
    } else {
      alert(`Cancelled accepting litter`);
    }
  };

  // Handle deleting a puppy object from litter.puppies
  const onDeletePuppy = (i) => {
    if (confirm(t("deletePuppyConfirmation"))) {
      remove(i);
    }
  };

  const onChangePuppy = (e, newValue) => {
    setSelectedPuppy(newValue);
  };

  const onAddPuppy = () => {
    append(selectedPuppy);
    setSelectedPuppy(null);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <FormHeader
                action={action}
                formType={formType}
                data={litter}
                handleModalClose={handleModalClose}
              />
              <AcceptButton
                action={action}
                formType={formType}
                registrationStatus={litter?.registrationStatus}
                handleAccept={handleAccept}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="dateOfBirth"
                control={control}
                defaultValue={litter?.dateOfBirth || null}
                rules={{
                  required: "Required",
                }}
                render={({ field: { ref, name, ...field } }) => (
                  <DatePicker
                    {...field}
                    required
                    inputRef={ref}
                    label="Date of birth"
                    onChange={(e) => field.onChange(dayjs(e).toISOString())}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        required
                        name={name}
                        error={errors?.dateOfBirth && true}
                        helperText={errors && errors?.dateOfBirth?.message}
                      />
                    )}
                    disableFuture
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="organizations"
                onChange={([, data]) => data}
                defaultValue={litterOrgs || null}
                control={control}
                render={({ field: { onChange, ...props } }) => (
                  <Autocomplete
                    fullWidth
                    multiple
                    label="Organization"
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        {option.name}
                      </li>
                    )}
                    options={organizations}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    getOptionLabel={(option) => `${option.name}`}
                    onChange={(e, data) => onChange(data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Organization"
                        helperText={
                          currentUser.role === "admin"
                            ? "Only select one!"
                            : currentUser.role === "manager"
                            ? `NOTE! If ${formType} is removed from your organization, you will lose access to it`
                            : null
                        }
                      />
                    )}
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider textAlign="left" sx={{ my: 2 }}>
                <Typography variant="h5">Breeder</Typography>
              </Divider>
            </Grid>
            {breeder === "hidden" ? (
              <Grid item xs={12}>
                <Alert severity="info">
                  <AlertTitle>
                    This litter has breeder that is outside of your organization
                  </AlertTitle>
                  Managers can not edit breeder information if the breeder is
                  part of a different organization. Please contact the
                  administrator for more information.
                </Alert>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="breeder"
                    rules={{
                      required: "Required",
                    }}
                    render={({ field: { onChange, ...props } }) => (
                      <Autocomplete
                        fullWidth
                        required
                        options={users}
                        getOptionLabel={(option) =>
                          `${option.name} (${option.email})`
                        }
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            {option.name} {" ("}
                            {option.email}
                            {") "}
                          </li>
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option._id == value._id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Breeder"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                        onChange={(e, data) => onChange(data)}
                        {...props}
                      />
                    )}
                    onChange={([, data]) => data}
                    defaultValue={selectedBreeder}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5">Breeder preview</Typography>
                  <List>
                    {formdata.breeder && (
                      <ListItem secondaryAction={formdata.breeder.regNumber}>
                        <ListItemText
                          primary={`${formdata.breeder.name}`}
                          secondary={`${formdata.breeder.email} (${formdata.breeder._id})`}
                        />
                      </ListItem>
                    )}
                  </List>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Divider textAlign="left" sx={{ my: 2 }}>
                <Typography variant="h5">Parents</Typography>
              </Divider>
            </Grid>
            <Grid item xs={12} md={6}>
              <ParentAutocomplete
                label="Sire"
                name="sire"
                options={maleDogs}
                value={selectedSire}
                control={control}
              />
              <List sx={{ mt: 2 }}>
                <ListItem>
                  <Typography variant="h5">Sire preview</Typography>
                </ListItem>
                {formdata.sire && (
                  <ListItem>
                    <ListItemButton
                      href={`/admin/dog/${formdata.sire._id}`}
                      target="_blank"
                    >
                      <ListItemIcon>
                        <Launch />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${formdata.sire.name} ${formdata.sire.regNumber}`}
                        secondary={`${formdata.sire._id}`}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <ParentAutocomplete
                label="Dam"
                name="dam"
                options={femaleDogs}
                value={selectedDam}
                control={control}
              />
              <List sx={{ mt: 2 }}>
                <ListItem>
                  <Typography variant="h5">Dam preview</Typography>
                </ListItem>
                {formdata.dam && (
                  <ListItem>
                    <ListItemButton
                      href={`/admin/dog/${formdata.dam._id}`}
                      target="_blank"
                    >
                      <ListItemIcon>
                        <Launch />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${formdata.dam.name} ${formdata.dam.regNumber}`}
                        secondary={`${formdata.dam._id}`}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Divider textAlign="left" sx={{ my: 2 }}>
                <Typography variant="h5">Puppies</Typography>
              </Divider>
              <PuppiesList
                puppies={formdata?.puppies || []}
                onDeletePuppy={onDeletePuppy}
                formType={formType}
              />
            </Grid>

            {formType === "tempLitter" ? (
              <>
                <Grid item xs={12} md={6}></Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    fullWidth
                    options={tempdogs}
                    value={selectedPuppy || null}
                    getOptionLabel={(option) =>
                      `${option.name} ${
                        option.regNumber || option.microchipNumber
                      }`
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        {option.name}{" "}
                        {option.regNumber || option.microchipNumber}
                      </li>
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option._id == value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Add a puppy"
                        helperText={"Only pending dogs can be added"}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    onChange={onChangePuppy}
                  />
                  <Button
                    variant="outlined"
                    onClick={onAddPuppy}
                    startIcon={<Add />}
                    disabled={!selectedPuppy}
                    sx={{ mt: 2 }}
                  >
                    Add puppy
                  </Button>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  sx={{ my: 2, justifyContent: "center" }}
                >
                  Puppies can be added or removed in pending litters only
                </Typography>
              </Grid>
            )}

            {action === "edit" && formType === "tempLitter" && (
              <Grid item xs={12}>
                <Divider textAlign="left" sx={{ my: 2 }}>
                  <Typography variant="h5">Verifications</Typography>
                </Divider>
                <VerificationsList verifications={litter.verifications} />
              </Grid>
            )}
            {action === "edit" && (
              <Grid item xs={12}>
                <Divider textAlign="left" sx={{ my: 2 }}>
                  <Typography variant="h5">Attachments</Typography>
                </Divider>
                <AttachmentList parentDoc={litter} />
              </Grid>
            )}

            <Grid item xs={12}>
              <JsonPreviewWidget formdata={formdata} />
            </Grid>
            <Grid item xs={12}>
              <FormButtons
                action={action}
                handleDelete={handleDelete}
                isSubmitting={isSubmitting}
                isDirty={isDirty}
                errors={errors}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}
