import { useParams } from "react-router-dom";
import { atom, selector, selectorFamily } from "recoil";
import {
  getAllCertificates,
  getAllCompetitionResults,
  getAllDogs,
  getAllGeneticResults,
  getAllHealthResults,
  getAllLitters,
  getAllOrganizations,
  getAllResultproviders,
  getAllTempDogs,
  getAllTempLitters,
  getAllTreedogs,
  getAllUsers,
  getCertificateById,
  getCompetitionResultById,
  getDogById,
  getGeneticResultById,
  getHealthResultById,
  getLitterById,
  getOrganizationById,
  getResultproviderById,
  getTempDogById,
  getTempLitterById,
  getTreedogById,
  getUserById,
} from "../api/adminRoutes";
import { accessTokenState } from "./globalStates";

// GET SELECTED DOCUMENT TYPE BY URL ID PARAM
export const selectedDocByIdState = selectorFamily({
  key: "selectedDocByIdState",
  get:
    (docType) =>
    async ({ get }) => {
      const { id: _id } = useParams(); // TODO: Pass this as props
      const accessToken = get(accessTokenState);

      switch (docType) {
        case "dog": {
          const response = getDogById(_id, { accessToken });
          if (response.error) {
            throw response;
          }
          return response;
        }
        case "tempDog": {
          const response = getTempDogById(_id, { accessToken });
          if (response.error) {
            throw response;
          }
          return response;
        }
        case "treedog": {
          const response = getTreedogById(_id, { accessToken });
          if (response.error) {
            throw response;
          }
          return response;
        }
        case "litter": {
          const response = getLitterById(_id, { accessToken });
          if (response.error) {
            throw response;
          }
          return response;
        }
        case "tempLitter": {
          const response = getTempLitterById(_id, { accessToken });
          if (response.error) {
            throw response;
          }
          return response;
        }
        case "certificate": {
          const response = getCertificateById(_id, { accessToken });
          if (response.error) {
            throw response;
          }
          return response;
        }
        case "organization": {
          const response = getOrganizationById(_id, { accessToken });
          if (response.error) {
            throw response;
          }
          return response;
        }
        case "resultprovider": {
          const response = getResultproviderById(_id, { accessToken });
          if (response.error) {
            throw response;
          }
          return response;
        }
        case "competitionResult": {
          const response = getCompetitionResultById(_id, { accessToken });
          if (response.error) {
            throw response;
          }
          return response;
        }
        case "geneticResult": {
          const response = getGeneticResultById(_id, { accessToken });
          if (response.error) {
            throw response;
          }
          return response;
        }
        case "healthResult": {
          const response = getHealthResultById(_id, { accessToken });
          if (response.error) {
            throw response;
          }
          return response;
        }
        case "user": {
          const response = getUserById(_id, { accessToken });
          if (response.error) {
            throw response;
          }
          return response;
        }

        default:
          break;
      }
    },
});

// SELECTED DOCUMENT TYPE BY URL ID PARAM
export const selectedDocState = atom({
  key: "selectedDocState",
  default: selectedDocByIdState,
});

// ADMIN ALL TEMPLITTERS
export const adminTempLittersState = selector({
  key: "adminTempLittersState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllTempLitters({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN ALL LITTERS
export const adminAllLittersState = selector({
  key: "adminAllLittersState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllLitters({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN ALL TEMPDOGS
export const adminTempDogsState = selector({
  key: "adminTempDogsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllTempDogs({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN ALL DOGS
export const adminAllDogsState = selector({
  key: "adminAllDogsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllDogs({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN DOG BY ID
export const adminDogByIdState = selectorFamily({
  key: "adminDogByIdState",
  get:
    (id) =>
    ({ get }) => {
      const dogs = get(adminAllDogsState);
      const found = dogs.find((c) => c._id === id);
      if (!found) {
        return null;
      }
      return found;
    },
});

// ADMIN FILTER DOGS BY GENDER
export const adminDogsByGenderState = selectorFamily({
  key: "adminDogsByGenderState",
  get:
    (gender) =>
    ({ get }) => {
      const dogs = get(adminAllDogsState);
      return dogs.filter((dog) => dog.sex === gender);
    },
});

// ADMIN ALL TREEDOGS
export const adminAllTreedogsState = selector({
  key: "adminAllTreedogsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllTreedogs({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN ALL USERS
export const adminAllUsersState = selector({
  key: "adminAllUsersState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllUsers({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN ALL ORGANIZATIONS
export const adminAllOrgsState = selector({
  key: "adminAllOrgsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllOrganizations({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN ALL RESULT PROVIDERS
export const adminAllResultprovidersState = selector({
  key: "adminAllResultprovidersState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllResultproviders({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN ALL CERTIFICATES
export const adminAllCertsState = selector({
  key: "adminAllCertsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllCertificates({ accessToken });
    if (response.error) {
      throw response;
    }
    if (response === null || response === undefined) {
      return [];
    }
    return response;
  },
});

// ADMIN ALL HEALTH RESULTS
export const adminAllHealthResultsState = selector({
  key: "adminAllHealthResultsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllHealthResults({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});
// ADMIN ALL GENETIC RESULTS
export const adminAllGeneticResultsState = selector({
  key: "adminAllGeneticResultsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllGeneticResults({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});
// ADMIN ALL COMPETITION RESULTS
export const adminAllCompetitionResultsState = selector({
  key: "adminAllCompetitionResultsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getAllCompetitionResults({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// ADMIN GET BREEDER BY ID
export const adminGetBreederByIdState = selectorFamily({
  key: "adminGetBreederByIdState",
  get:
    (breeder) =>
    ({ get }) => {
      const users = get(adminAllUsersState);
      if (!breeder) {
        return null;
      }
      if (breeder) {
        const breederObject = users.find((u) => u._id === breeder);
        if (breederObject) {
          return breederObject;
        } else {
          return "hidden";
        }
      }
    },
});

// ADMIN GET OWNERS BY ID
export const adminGetOwnersByIdState = selectorFamily({
  key: "adminGetOwnersByIdState",
  get:
    (owners) =>
    ({ get }) => {
      const users = get(adminAllUsersState);
      if (!owners) {
        return [];
      }
      let array = [];
      owners.forEach((o) => {
        if (o !== null) {
          const ownerObject = users.find((u) => u._id === o);
          if (ownerObject) {
            array.push(ownerObject);
          } else {
            array.push("hidden");
          }
        }
      });
      return array;
    },
});
