import React, { useState } from "react";

import {
  Grid,
  TextField,
  Select,
  FormControl,
  MenuItem,
  Autocomplete,
  InputLabel,
  FormHelperText,
} from "@mui/material";

import {
  createNewOrganization,
  deleteOrganization,
  updateOrganization,
} from "../../../api/adminRoutes";

import { accessTokenState, snackBarState } from "../../../recoil/globalStates";
import { adminAllOrgsState } from "../../../recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import JsonPreviewWidget from "../../../components/adminDashboardComponents/jsonPreviewWidget";
import FormHeader from "../../../components/adminDashboardComponents/formHeader";
import FormButtons from "../../../components/adminDashboardComponents/formButtons";

function OrganizationForm({ action, org, handleModalClose }) {
  const { t } = useTranslation();
  const accessToken = useRecoilValue(accessTokenState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const organizations = useRecoilValue(adminAllOrgsState);

  const [initialOrg, setInitialOrg] = useState(org);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm();

  const formdata = watch();

  const onSubmit = (data) => {
    if (action === "add") {
      createNewOrganization(data, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setSnackbarState({
            message: t("creationSuccess", { docType: "Organization" }),
            severity: "success",
            open: true,
          });
        }
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    }
    if (action === "edit") {
      updateOrganization(initialOrg._id, data, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setSnackbarState({
            message: t("updateSuccess", { docType: "Organization" }),
            severity: "success",
            open: true,
          });
        }
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    }
  };

  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete this organization? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteOrganization(initialOrg._id, { accessToken }).then(
          (res) => {
            if (res.error) {
              setSnackbarState({
                message: res.message,
                severity: "error",
                open: true,
              });
            } else {
              alert(`Organization has been removed.`);
              setTimeout((window.location = `/admin/allorganizations`), 3000);
            }
          }
        );
      };
      deleteData();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          spacing={3}
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <FormHeader
              action={action}
              formType="organization"
              data={initialOrg}
              handleModalClose={handleModalClose}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="name"
              control={control}
              defaultValue={initialOrg?.name || ""}
              rules={{
                minLength: 1,
                required: "Required",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  label="Name of organization"
                  error={errors?.name}
                  helperText={errors?.name && errors?.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Type *</InputLabel>
              <Controller
                name="type"
                control={control}
                defaultValue={initialOrg?.type || ""}
                rules={{
                  minLength: 1,
                  required: "Required",
                }}
                render={({ field: { ref, name, ...field } }) => (
                  <Select {...field} fullWidth required label="Type">
                    <MenuItem key={0} value="member">
                      Member
                    </MenuItem>
                    <MenuItem key={1} value="partner">
                      Partner
                    </MenuItem>
                  </Select>
                )}
              />
              <FormHelperText>{errors?.type?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="parentOrg"
              onChange={([, data]) => data}
              defaultValue={initialOrg?.parentOrg || null}
              control={control}
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  freeSolo
                  label="Parent organization"
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.name}
                    </li>
                  )}
                  options={organizations}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(e, data) => onChange(data)}
                  renderInput={(params) => (
                    <TextField {...params} label="Parent organization" />
                  )}
                  {...props}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="url"
              defaultValue={initialOrg?.url || ""}
              control={control}
              render={({ field: { ref, name, ...field } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Organization URL"
                  helperText="Include https:// in the url"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <JsonPreviewWidget formdata={formdata} />
          </Grid>
          <Grid item xs={12}>
            <FormButtons
              action={action}
              handleDelete={handleDelete}
              isSubmitting={isSubmitting}
              isDirty={isDirty}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
export default OrganizationForm;
