import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import FormModal from "../../../components/adminDashboardComponents/formModal";
import GenetictestForm from "../forms/genetictestForm";
import { getGeneticTestColumns } from "../../../components/adminDashboardComponents/adminDatagridColumns";

// Routes
import { createNewGeneticResult } from "../../../api/adminRoutes";

// MUI
import { Typography, Grid, Button, Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Add from "@mui/icons-material/Add";

// Recoil
import { snackBarState } from "../../../recoil/globalStates";
import { adminAllGeneticResultsState } from "../../../recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";

function AdminAllGeneticResults() {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const results = useRecoilValue(adminAllGeneticResultsState);

  const handleRowClick = (params) => {
    window.location = `/admin/geneticresult/${params.row._id}`;
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    if (confirm(t("confirmModalClose"))) {
      setModalOpen(false);
    }
  };

  const handleSubmit = (accessToken, updatedResult) => {
    createNewGeneticResult(updatedResult, { accessToken }).then((res) => {
      if (res.error) {
        setSnackbarState({
          message: res.message,
          severity: "error",
          open: true,
        });
      } else {
        setSnackbarState({
          message: t("creationSuccess", { docType: "Genetic result" }),
          severity: "success",
          open: true,
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems="center"
            alignContent={"center"}
            spacing={{ xs: 2, sm: 0 }}
          >
            <Typography variant="h2">Genetic Results</Typography>{" "}
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={handleModalOpen}
            >
              Add Genetic Result
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            pageSize={50}
            onRowClick={handleRowClick}
            rows={results}
            getRowId={(row) => row._id}
            columns={getGeneticTestColumns("geneticresult")}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </Grid>
      </Grid>
      <FormModal open={modalOpen} onClose={handleModalClose}>
        <GenetictestForm
          action="add"
          handleModalClose={handleModalClose}
          emitHandleSubmit={handleSubmit}
        />
      </FormModal>
    </>
  );
}

export default AdminAllGeneticResults;
