import React, { useEffect, useState } from "react";

// Routes
import { deleteTreedog } from "../../../api/adminRoutes";

// MUI
import {
  Grid,
  TextField,
  Divider,
  Typography,
  ListItem,
  List,
  ListItemText,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import { Launch } from "@mui/icons-material";

import {
  accessTokenState,
  snackBarState,
  userState,
} from "../../../recoil/globalStates";
import {
  adminAllTreedogsState,
  adminDogsByGenderState,
} from "../../../recoil/adminStates";
import { constSelector, useRecoilState, useRecoilValue } from "recoil";
import FormHeader from "../../../components/adminDashboardComponents/formHeader";
import { Controller, useForm } from "react-hook-form";
import FormButtons from "../../../components/adminDashboardComponents/formButtons";
import JsonPreviewWidget from "../../../components/adminDashboardComponents/jsonPreviewWidget";
import { useAuth0 } from "@auth0/auth0-react";

// This form is also used for tempDogs
function TreedogForm({ dog, action, emitHandleSubmit, handleModalClose }) {
  const { isAuthenticated } = useAuth0();
  const currentUser = useRecoilValue(
    isAuthenticated ? userState : constSelector(null)
  );

  const accessToken = useRecoilValue(accessTokenState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const femaleDogs = useRecoilValue(adminDogsByGenderState("female"));
  const maleDogs = useRecoilValue(adminDogsByGenderState("male"));

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm();

  const formdata = watch();

  const [initialDog, setInitialDog] = useState(action === "edit" ? dog : null);

  const treedogs = useRecoilValue(adminAllTreedogsState);

  const [selectedSire, setSelectedSire] = useState(dog?.sire || null);
  const [selectedDam, setSelectedDam] = useState(dog?.dam || null);

  // setting the parents since for some reason it doesnt do it on its own
  useEffect(() => {
    setValue("sire", selectedSire);
    setValue("dam", selectedDam);
  }, [initialDog]);

  // Handle form submit, call the parent components handleSubmit function that send the document to API patch
  const onSubmit = (data) => {
    // nullify unique fields before sending them if theyre empty strings
    data.microchipNumber =
      data.microchipNumber === "" ? undefined : data.microchipNumber;

    data.regNumber = data.regNumber === "" ? undefined : data.regNumber;

    // add current users organization to tree dog
    if (currentUser.organizations[0]) {
      data.organizations = [currentUser.organizations[0]];
    }

    emitHandleSubmit(accessToken, data, initialDog?._id);
  };

  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete this treedog? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteTreedog(initialDog._id, { accessToken }).then((res) => {
          if (res.error) {
            setSnackbarState({
              message: res.message,
              severity: "error",
              open: true,
            });
          } else {
            alert(`Treedog has been removed.`);
            setTimeout((window.location = `/admin/alltreedogs`), 3000);
          }
        });
      };
      deleteData();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" spacing={3} alignItems="flex-start">
          <Grid item xs={12}>
            <FormHeader
              action={action}
              formType="treedog"
              data={initialDog}
              handleModalClose={handleModalClose}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="name"
              control={control}
              rules={{ required: "Required" }}
              defaultValue={initialDog?.name || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Dog's name"
                  error={errors?.name}
                  helperText={errors?.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="microchipNumber"
              control={control}
              defaultValue={initialDog?.microchipNumber || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Microchip number"
                  helperText="Needs to be unique"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="regNumber"
              control={control}
              defaultValue={initialDog?.regNumber || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Registration number"
                  helperText="Needs to be unique"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="registry"
              control={control}
              defaultValue={initialDog?.registry || ""}
              render={({ field }) => (
                <TextField {...field} fullWidth label="Registry" />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="additionalInfo"
              control={control}
              defaultValue={initialDog?.additionalInfo || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Additional information"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Family</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel>{"Sire's document type"}</FormLabel>
            <Controller
              name="sireDocType"
              control={control}
              defaultValue={initialDog?.sireDocType || "dog"}
              render={({ field }) => (
                <RadioGroup {...field} row sx={{ pb: 2 }}>
                  <FormControlLabel
                    value="dog"
                    control={<Radio />}
                    label={<Typography variant="body">Dog</Typography>}
                  />
                  <FormControlLabel
                    value="treedog"
                    control={<Radio />}
                    label={<Typography variant="body">Treedog</Typography>}
                  />
                </RadioGroup>
              )}
            />
            <Controller
              name="sire"
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  options={formdata.sireDocType === "dog" ? maleDogs : treedogs}
                  getOptionLabel={(option) =>
                    `${option.name} ${option.regNumber}`
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.name} {option.regNumber}
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option._id == value._id
                  }
                  autoComplete
                  noOptionsText={"no data"}
                  renderInput={(params) => (
                    <TextField {...params} label="Sire" />
                  )}
                  onChange={(e, data) => onChange(data)}
                  {...props}
                />
              )}
              onChange={([, data]) => data}
              defaultValue={selectedSire || null}
              control={control}
            />
            <List sx={{ mt: 2 }}>
              <ListItem>
                <Typography variant="h5">Sire preview</Typography>
              </ListItem>
              {formdata.sire && (
                <ListItem>
                  <ListItemButton
                    href={`/admin/${formdata.sireDocType}/${formdata.sire._id}`}
                    target="_blank"
                  >
                    <ListItemIcon>
                      <Launch />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${formdata.sire.name} ${formdata.sire.regNumber}`}
                      secondary={`${formdata.sire._id}`}
                    />{" "}
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel>{"Dam's document type"}</FormLabel>
            <Controller
              name="damDocType"
              control={control}
              defaultValue={initialDog?.damDocType || "dog"}
              render={({ field }) => (
                <RadioGroup {...field} row sx={{ pb: 2 }}>
                  <FormControlLabel
                    value="dog"
                    control={<Radio />}
                    label={<Typography variant="body">Dog</Typography>}
                  />
                  <FormControlLabel
                    value="treedog"
                    control={<Radio />}
                    label={<Typography variant="body">Treedog</Typography>}
                  />
                </RadioGroup>
              )}
            />
            <Controller
              name="dam"
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  options={
                    formdata.damDocType === "dog" ? femaleDogs : treedogs
                  }
                  getOptionLabel={(option) =>
                    `${option.name} ${option.regNumber}`
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.name} {option.regNumber}
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option._id == value._id
                  }
                  autoComplete
                  noOptionsText={"no data"}
                  renderInput={(params) => (
                    <TextField {...params} label="Dam" />
                  )}
                  onChange={(e, data) => onChange(data)}
                  {...props}
                />
              )}
              onChange={([, data]) => data}
              defaultValue={selectedDam || null}
              control={control}
            />
            <List sx={{ mt: 2 }}>
              <ListItem>
                <Typography variant="h5">Dam preview</Typography>
              </ListItem>
              {formdata.dam && (
                <ListItem>
                  <ListItemButton
                    href={`/admin/${formdata.damDocType}/${formdata.dam._id}`}
                    target="_blank"
                  >
                    <ListItemIcon>
                      <Launch />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${formdata.dam.name} ${formdata.dam.regNumber}`}
                      secondary={`${formdata.dam._id}`}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={12}>
            <JsonPreviewWidget formdata={formdata} />
          </Grid>
          <Grid item xs={12}>
            <FormButtons
              action={action}
              handleDelete={handleDelete}
              isSubmitting={isSubmitting}
              isDirty={isDirty}
              errors={errors}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default TreedogForm;
