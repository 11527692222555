import React, { Suspense } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// Components
import { LoaderBlock } from "../../../elements/Loader";

// MUI
import {
  Grid,
  Paper,
  Typography,
  Link,
  Box,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AdminNav from "../../../components/adminDashboardComponents/adminNav";
import { Outlet } from "react-router";
import { useRecoilValue } from "recoil";
import { userState } from "../../../recoil/globalStates";
import { getDashboardAccess } from "../../../constants/helpers";

function AdminContainer() {
  const { isAuthenticated } = useAuth0();
  const dbUser = useRecoilValue(userState);
  const canAccessDashboard = getDashboardAccess(dbUser.role);

  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("md"));
  const padding = lessThanSmall ? 2 : 4;

  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        {isAuthenticated && canAccessDashboard ? (
          <>
            <Grid item xs={12}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={3}
                alignItems={{ xs: "center", sm: "baseline" }}
              >
                <Typography
                  component={Link}
                  href="/admin/dashboard"
                  variant="h1"
                  sx={{
                    color: "primary",
                    textDecoration: "none",
                  }}
                >
                  Datacanis Dashboard
                </Typography>
                <Typography variant="caption">as {dbUser.role}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <AdminNav />
            </Grid>
            <Grid item xs={12}>
              <Suspense
                fallback={
                  <Box sx={{ p: 4 }}>
                    <LoaderBlock />
                  </Box>
                }
              >
                <Paper sx={{ p: padding }}>
                  <Outlet />
                </Paper>
              </Suspense>
            </Grid>
          </>
        ) : (
          <Typography variant="body">
            Insufficient permissions, contact administrator.
          </Typography>
        )}
      </Grid>
    </>
  );
}

export default AdminContainer;
