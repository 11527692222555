import React, { useState } from "react";
import { getTempLitterColumns } from "../../../components/adminDashboardComponents/adminDatagridColumns";

// Components
import FormModal from "../../../components/adminDashboardComponents/formModal";

// Routes
import { createNewTempLitter } from "../../../api/adminRoutes";

// MUI
import { Typography, Grid, Button, Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Add from "@mui/icons-material/Add";
import LitterForm from "../forms/litterForm";
import { t } from "i18next";

import { snackBarState } from "../../../recoil/globalStates";
import {
  adminAllUsersState,
  adminTempLittersState,
} from "../../../recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";

export default function AdminAllTempLitters() {
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [modalOpen, setModalOpen] = useState(false);

  const litters = useRecoilValue(adminTempLittersState);
  const users = useRecoilValue(adminAllUsersState);

  const handleRowClick = (params) => {
    window.location = `/admin/templitter/${params.row._id}`;
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    if (confirm(t("confirmModalClose"))) {
      setModalOpen(false);
    }
  };

  const handleSubmit = (accessToken, updatedLitter) => {
    createNewTempLitter(updatedLitter, { accessToken }).then((res) => {
      if (res.error) {
        setSnackbarState({
          message: res.message,
          severity: "error",
          open: true,
        });
      } else {
        setSnackbarState({
          message: t("creationSuccess", { docType: "TempLitter" }),
          severity: "success",
          open: true,
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems="center"
            alignContent={"center"}
            spacing={{ xs: 2, sm: 0 }}
          >
            <Typography variant="h2">Pending litters</Typography>{" "}
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={handleModalOpen}
            >
              Add pending litter
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            pageSize={50}
            onRowClick={handleRowClick}
            rows={litters}
            getRowId={(row) => row._id}
            columns={getTempLitterColumns("templitter")}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </Grid>
      </Grid>
      <FormModal open={modalOpen} onClose={handleModalClose}>
        <LitterForm
          formType="tempLitter"
          action="add"
          users={users}
          handleModalClose={handleModalClose}
          emitHandleSubmit={handleSubmit}
        />
      </FormModal>
    </>
  );
}
