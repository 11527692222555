import React, { useContext } from "react";
import { UserContext } from "../../../App";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import {
  countFemales,
  countMales,
  getKruValueLabel,
} from "../../../constants/helpers";
import { litterRegRules } from "../../../constants/rules";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Divider,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  Stack,
  Alert,
  TableContainer,
  FormControl,
  FormControlLabel,
  Button,
} from "@mui/material";
import { Launch } from "@mui/icons-material";

import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "../../../recoil/globalStates";
import {
  attachmentsState,
  filepathsState,
  statsState,
} from "../../../recoil/registrationStates";
import { Controller, useFormContext } from "react-hook-form";

export default function LitterSummary() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { control, watch } = useFormContext();
  const litter = watch();

  const { checkboxes } = watch();

  const user = useRecoilValue(userState);

  const [attachments, setAttachments] = useRecoilState(attachmentsState);

  const hasAttachments =
    attachments.sireCertificate.length > 0 ||
    attachments.damCertificate.length > 0 ||
    attachments.microchipCert.length > 0 ||
    attachments.misc.length > 0
      ? true
      : false;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2">{t("summary")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left">
          <Typography variant="h3">{t("breeder")}</Typography>
        </Divider>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              {user?.name}
              <br />
              {user?.address || t("noData")} {user?.postalCode || " "}{" "}
              {user?.city || " "} <br />
              {user?.phone || t("noData")}
              <br />
              {user?.email || t("noData")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <Typography variant="body1">
              {t("breederPrefix")}: {user?.breedingPrefix || t("noData")}
              <br />
              {t("permissionToPublishName")}:{" "}
              {user?.permissions?.name ? t("yes") : t("no")}
              <br />
              {t("permissionToPublishCity")}:{" "}
              {user?.permissions?.city ? t("yes") : t("no")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("registry")}</Typography>
        </Divider>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{litter.registry?.name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Button
          href={litter.registry?.url}
          target="_blank"
          endIcon={<Launch />}
        >
          {t("registryGuidelines")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left">
          <Typography variant="h3">{t("litterParents")}</Typography>
        </Divider>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">{t("sire")}</Typography>
            <Typography variant="body1">
              {litter.sire?.name || t("noData")} {litter.sire?.regNumber}
              <br />
              {t("DOB")} {dayjs(litter.sire?.dateOfBirth).format("DD.MM.YYYY")}
            </Typography>
            <br />
            <Typography variant="h5">
              {t("deliveryOfMatingCertificate")}:
            </Typography>
            <Typography variant="body1">
              {litter.verificationNeeded?.sire === "email"
                ? t("emailVerification")
                : litter.verificationNeeded?.sire === "attachment"
                ? t("attachment")
                : t("notNeededInfoText")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">{t("dam")}</Typography>
            <Typography variant="body1">
              {litter.dam?.name || t("noData")} {litter.dam?.regNumber}
              <br />
              {t("DOB")} {dayjs(litter.dam?.dateOfBirth).format("DD.MM.YYYY")}
            </Typography>
            <br />
            <Typography variant="h5">
              {t("deliveryOfMatingCertificate")}:
            </Typography>
            <Typography variant="body1">
              {litter.verificationNeeded?.dam === "email"
                ? t("emailVerification")
                : litter.verificationNeeded?.dam === "attachment"
                ? t("attachment")
                : t("notNeededInfoText")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">
              <Typography variant="body">
                {t("emailVerificationInfoText")}
              </Typography>
            </Alert>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("litterInformation")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("dob")}</TableCell>
                <TableCell>{t("countryOfOrigin")}</TableCell>
                <TableCell>{t("countOfDeadPuppies")}</TableCell>
                <TableCell>{t("mating")}</TableCell>
                <TableCell>{t("birth")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {dayjs(litter.dateOfBirth).format("DD.MM.YYYY") ||
                    t("notProvided")}
                </TableCell>
                <TableCell>
                  {litter.countryOfOrigin?.[language] || t("notProvided")}
                </TableCell>
                <TableCell>
                  {litter.stats?.deadPuppies || t("notAnswered")}
                </TableCell>
                <TableCell>
                  {t(litter.stats?.mating) || t("notAnswered")}
                </TableCell>
                <TableCell>
                  {t(litter.stats?.birth) || t("notAnswered")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("puppies2")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("name")}</TableCell>
                <TableCell>{t("microchipNumber")}</TableCell>
                <TableCell>{t("sex")}</TableCell>
                <TableCell>{t("color")}</TableCell>
                <TableCell>{t("coat")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {litter.puppies?.length ? (
                litter.puppies?.map((puppy, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{puppy.name || "-"}</TableCell>
                      <TableCell>{puppy.microchipNumber || "-"}</TableCell>
                      <TableCell>{t(puppy.sex) || "-"}</TableCell>
                      <TableCell>
                        {getKruValueLabel(puppy.color, language)}
                        {(puppy.markings &&
                          puppy.markings.map((v, i) => {
                            return (
                              <div key={i}>
                                {getKruValueLabel(v, language)}
                                <br />
                              </div>
                            );
                          })) ||
                          "-"}
                      </TableCell>
                      <TableCell>
                        {getKruValueLabel(puppy.coattype, language)}
                        {(puppy.coatqualities &&
                          puppy.coatqualities.map((v, i) => {
                            return (
                              <div key={i}>
                                {getKruValueLabel(v, language)}
                                <br />
                              </div>
                            );
                          })) ||
                          "-"}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>{t("noPuppies")}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={6}>
                  <Stack
                    direction="row"
                    spacing={4}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Typography variant="body">
                      {t("males")} {countMales(litter.puppies)}
                    </Typography>
                    <Typography variant="body">
                      {t("females")} {countFemales(litter.puppies)}
                    </Typography>
                    <Typography variant="body">
                      {t("total")} {litter.puppies?.length}
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("attachments")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            {hasAttachments ? (
              <TableBody>
                {attachments.sireCertificate.length > 0 && (
                  <TableRow>
                    <TableCell>{t("siresMatingCertificate")}</TableCell>
                    <TableCell>
                      {attachments.sireCertificate.map((e, i) => (
                        <p key={i}>{e.get(`attachment`).name}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
                {attachments.damCertificate.length > 0 && (
                  <TableRow>
                    <TableCell>{t("damsMatingCertificate")}</TableCell>
                    <TableCell>
                      {attachments.damCertificate.map((e, i) => (
                        <p key={i}>{e.get(`attachment`).name}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
                {attachments.microchipCert.length > 0 && (
                  <TableRow>
                    <TableCell>{t("puppiesMicrochipCertificates")}</TableCell>
                    <TableCell>
                      {attachments.microchipCert.map((e, i) => (
                        <p key={i}>{e.get(`attachment`).name}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
                {attachments.misc.length > 0 && (
                  <TableRow>
                    <TableCell>{t("otherAttachments")}</TableCell>
                    <TableCell>
                      {attachments.misc.map((e, i) => (
                        <p key={i}>{e.get(`attachment`).name}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell>{t("noAttachments")}</TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography variant="h3">{t("termsAndPayment")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("description")}</TableCell>
                <TableCell>{t("pricePerPuppy")}</TableCell>
                <TableCell>{t("numberOfPuppies")}</TableCell>
                <TableCell>{t("total")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t("litterRegistration")}</TableCell>
                <TableCell>
                  {litterRegRules.pricePerPuppy.toFixed(2)} €
                </TableCell>
                <TableCell>{litter.puppies?.length}</TableCell>
                <TableCell>
                  {(
                    litter.puppies?.length * litterRegRules.pricePerPuppy
                  ).toFixed(2)}{" "}
                  €
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="right">
                  <Typography variant="caption">
                    {t("anyDiscountsCalculatedAtCheckout")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Stack spacing={2} sx={{ mt: 3 }}>
          <FormControl>
            <FormControlLabel
              control={
                <Controller
                  name="checkboxes.dataVerified"
                  control={control}
                  defaultValue={checkboxes?.dataVerified || false}
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} />
                  )}
                />
              }
              label={t("breederConfirmation")}
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Controller
                  name="checkboxes.eulaAgreement"
                  control={control}
                  defaultValue={checkboxes?.eulaAgreement || false}
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} />
                  )}
                />
              }
              label={t("termsConfirmation", {
                registryName: litter.registry?.name,
              })}
            />
          </FormControl>
        </Stack>
      </Grid>
    </Grid>
  );
}
