import React from "react";
import { DeleteForever, Save } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { userState } from "../../recoil/globalStates";

export default function FormButtons({
  action,
  handleDelete,
  isSubmitting,
  isDirty,
  errors,
}) {
  const dbUser = useRecoilValue(userState);

  return (
    <Grid
      container
      direction="row"
      justifyContent={{ xs: "center", sm: "space-between" }}
      alignItems={"center"}
      spacing={{ xs: 6, sm: 0 }}
    >
      <Grid item xs={12} sm={6}>
        {action === "edit" && dbUser.role === "admin" && (
          <Button
            variant="outlined"
            color="error"
            onClick={handleDelete}
            sx={{ mr: 2, float: "left" }}
          >
            <DeleteForever fontSize="small" sx={{ mr: 1 }} /> Delete
          </Button>
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        {errors && Object.keys(errors).length > 0 && (
          <Typography color="error" variant="body">
            Check the field inputs
          </Typography>
        )}

        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting || !isDirty}
          sx={{ mr: 2, float: "right" }}
        >
          <Save fontSize="small" sx={{ mr: 1 }} /> Save changes
        </Button>
      </Grid>
    </Grid>
  );
}
