import * as React from "react";
import dayjs from "dayjs";
import i18next, { t } from "i18next";

import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Chip,
  Typography,
  TableBody,
  Table,
  TableRow,
  TableCell,
  TableContainer,
} from "@mui/material";

import { getBadge, getCountryByCode } from "../../constants/helpers";

export default function BreederInfoBox({ breeder }) {
  const breederBadge = getBadge(breeder);
  const breederBadgeLabel =
    breeder?.certificates?.length > 0
      ? i18next.t("certified")
      : i18next.t("notCertified");

  const errorNoPrem = i18next.t("noPermissionToDisplayInformation");

  return (
    <>
      <List disablePadding>
        <ListItem
        /* secondaryAction={
            <Chip
              sx={{ display: { xs: "none", sm: "flex" } }}
              icon={breederBadge}
              label={breederBadgeLabel}
            />
          } */
        >
          <ListItemText
            primary={
              <Typography variant="h2">
                {breeder?.breedingPrefix || i18next.t("noBreederPrefix")}
              </Typography>
            }
            secondary={<>ID: {breeder?.breederId}</>}
          />
        </ListItem>
      </List>
      <TableContainer component={Paper}>
        <Table
          sx={{
            tableLayout: { xs: "fixed" },
            overflowWrap: { xs: "break-word" },
          }}
        >
          <TableBody>
            <TableRow>
              <TableCell variant="head">{i18next.t("name")}</TableCell>
              <TableCell>{breeder?.name || errorNoPrem}</TableCell>
              <TableCell variant="head">{i18next.t("location")}</TableCell>
              <TableCell>
                {breeder?.city}
                {breeder?.country && breeder?.city ? `, ` : null}
                {breeder?.country &&
                  getCountryByCode(breeder?.country, i18next.language)}
                {!breeder?.country && !breeder?.city && errorNoPrem}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">{i18next.t("certificates")}</TableCell>

              {breeder?.certificates?.length > 0 ? (
                breeder?.certificates?.map((cert) => (
                  <React.Fragment key={cert._id}>
                    <TableCell> {cert.certificate.name}</TableCell>
                    <TableCell colSpan={2}>
                      {t("issuingDate")}{" "}
                      {cert.issuingDate
                        ? dayjs(cert.issuingDate).format("DD.MM.YYYY")
                        : null}
                    </TableCell>
                  </React.Fragment>
                ))
              ) : (
                <TableCell colSpan={3}>{t("noCertificates")}</TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
