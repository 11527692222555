import React from "react";
import { useTranslation } from "react-i18next";

// Routes
import { updateTempDog } from "../../../api/adminRoutes";

// Components
import DogForm from "../forms/dogForm";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { snackBarState } from "../../../recoil/globalStates";
import { selectedDocByIdState } from "../../../recoil/adminStates";
import { AdminNotFound } from "../../../components/adminDashboardComponents/adminNotFound";

function AdminTempDogView() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const dog = useRecoilValue(selectedDocByIdState("tempDog"));

  const handleSubmit = (accessToken, updatedDog, id) => {
    const updateDogData = async (accessToken, updatedDog, id) => {
      await updateTempDog(id, updatedDog, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setSnackbarState({
            message: t("updateSuccess", { docType: "TempDog" }),
            severity: "success",
            open: true,
          });
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      });
    };
    updateDogData(accessToken, updatedDog, id);
  };

  return dog ? (
    <DogForm
      dog={dog}
      formType="tempdog"
      action="edit"
      emitHandleSubmit={handleSubmit}
    />
  ) : (
    <AdminNotFound docType="Dog" />
  );
}

export default AdminTempDogView;
