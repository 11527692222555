import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// API
import {
  getTempDogById,
  registerTempDog,
  checkIfMicrochipExists,
  addAttachment,
  createCheckout,
} from "../../api/privateRoutes";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Alert,
  AlertTitle,
  Stack,
} from "@mui/material";
import { Box, Button } from "@mui/material";

// STEPS
import DogRegistrationForm from "./steps/dogRegistrationForm";
import DogAttachments from "./steps/dogAttachments";
import DogSummary from "./steps/dogSummary";
import { accessTokenState, userState } from "../../recoil/globalStates";
import { useRecoilValue } from "recoil";

export default function DogRegistration() {
  const { t } = useTranslation();
  const { id } = useParams();

  const accessToken = useRecoilValue(accessTokenState);
  const user = useRecoilValue(userState);

  const [formErrors, setFormErrors] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const totalSteps = 3;

  const [checkboxes, setCheckboxes] = useState({
    ownerVerified: false, // At form page
    dataVerified: false,
    eulaAgreement: false,
  });
  const [dog, setDog] = useState({
    name: null,
    microchipNumber: null,
    dateOfBirth: null,
    sex: "male",
    countryOfOrigin: null,
    omistaja: null,
    registry: "",
    organizations: [],
    regOther: [],
    color: "",
    markings: [],
    coattype: "",
    coatqualities: [],
  });

  const [attachments, setAttachments] = useState({
    microchipCert: [],
    pedigreeCert: [],
    misc: [],
  });

  const [filePaths, setFilePaths] = useState({
    sireCertificate: null,
    damCertificate: null,
    microchipCertificates: null,
    misc: null,
  });

  // Get dog to continue registration. TODO: make the form handle resuming.
  useEffect(() => {
    if (!id || !accessToken || dog._id || activeStep != 0) return;
    getTempDogById(id, { accessToken }).then((d) => {
      setDog(d);
      setCheckboxes({ ...checkboxes, dataVerified: true });
      setActiveStep(1);
    });
  }, [id, dog._id, accessToken]);

  //---------------------- ERROR HANDLING -----------------------------

  const checkErrors = async () => {
    const errors = {};
    if (!dog.omistaja) errors.ownerIsNull = true;
    if (!dog.name) errors.nameIsNull = true;
    if (!dog.microchipNumber) errors.microchipIsNull = true;
    if (!dog.dateOfBirth) errors.dobIsNull = true;
    if (!dog.registry) errors.registryIsNull = true;
    if (!dog._id && dog.microchipNumber) {
      if (await checkIfMicrochipExists(dog.microchipNumber, { accessToken }))
        errors.microChipExists = true;
    }
    setFormErrors(errors);
    return Object.keys(errors).length;
  };

  //----------------------- STEP HANDLING -----------------------------

  /**
   * Handles the form submission, creating a new tempdog if values have changed.
   *
   */
  const handleForm = async () => {
    if ((await checkErrors()) !== 0) return;
    // TODO: handle updating tempdog if values have changed.
    if (dog._id) return setActiveStep(activeStep + 1);

    try {
      const { color, markings, coattype, coatqualities, registry } = dog;

      const falseyFilter = (v) => !!v;
      const { _id, ...transformedDog } = {
        ...dog,
        color: [color, ...markings].filter(falseyFilter),
        coat: [coattype, ...coatqualities].filter(falseyFilter),
        organizations: [registry._id],
      };

      const response = await registerTempDog(transformedDog, { accessToken });

      if (response.error) {
        setFormErrors({ genericError: response?.message });
        return console.error(response);
      }

      setDog({ ...dog, _id: response._id });
      setActiveStep(activeStep + 1);
    } catch (error) {
      setFormErrors({ genericError: error.message });
      console.error(error);
    }
  };

  const handleAttachments = async () => {
    try {
      // Collect all attachments into a flat array
      const allAttachments = Object.values(attachments).reduce(
        (flat, category) => flat.concat(category),
        []
      );
      // Loop throuh all attachments
      for (const attachment of allAttachments) {
        attachment.set(`parentDocType`, `tempdog`);
        attachment.set(`parentDoc`, dog._id);
        await addAttachment(attachment, { accessToken });
      }
      // Go next
      handleNext();
    } catch (error) {
      setFormErrors({ genericError: error.message });
      console.error(error);
    }
  };

  const handlePayment = async () => {
    try {
      const order = await createCheckout({
        accessToken,
        parentDoc: dog._id,
        parentDocType: "tempdog",
        organizations: [dog.registry._id],
      });
      if (!order.url) throw Error(`Cannot redirect to Stripe`);
      window.location.assign(order.url);
    } catch (error) {
      setFormErrors({ genericError: error.message });
      console.error(error);
    }
  };

  // Increase activestep to show next step + check for invalid entries
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  // Decrease activestep to show previous step
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function Errors() {
    const { genericError } = formErrors;
    return Object.keys(formErrors).length !== 0 ? (
      <Typography variant="caption" color="error">
        {genericError !== undefined ? genericError : t("errorCheckInput")}
      </Typography>
    ) : null;
  }

  function NextButton() {
    return activeStep === 0 ? (
      <Button onClick={handleForm}>{t("next")}</Button>
    ) : activeStep === 1 ? (
      <Button onClick={handleAttachments}>{t("next")}</Button>
    ) : activeStep + 1 === totalSteps ? (
      <Button
        onClick={handlePayment}
        disabled={!checkboxes.dataVerified || !checkboxes.eulaAgreement}
      >
        {t("proceedToPay")}
      </Button>
    ) : (
      <Button onClick={handleNext}>{t("next")}</Button>
    );
  }

  function CurrentStep() {
    return activeStep === 0 ? (
      <DogRegistrationForm
        dog={dog}
        setDog={setDog}
        formErrors={formErrors}
        checkboxes={checkboxes}
        setCheckboxes={setCheckboxes}
      />
    ) : activeStep === 1 ? (
      <DogAttachments
        attachments={attachments}
        setAttachments={setAttachments}
        filePaths={filePaths}
        setFilePaths={setFilePaths}
      />
    ) : activeStep === 2 ? (
      <DogSummary
        dog={dog}
        attachments={attachments}
        checkboxes={checkboxes}
        setCheckboxes={setCheckboxes}
      />
    ) : null;
  }

  return (
    <>
      <Paper sx={{ p: { xs: 2, sm: 4 } }}>
        {user && !user.isVerified && (
          <Alert severity="error" sx={{ mb: 3 }}>
            <AlertTitle>{t("accountNotVerifiedErrorTitle")}</AlertTitle>
            {t("accountNotVerifiedErrorText")}
          </Alert>
        )}
        <Grid container spacing={{ xs: 3, sm: 5 }}>
          <Grid xs={12}>
            <Stack
              display="flex"
              justifyContent="space-between"
              sx={{ flexDirection: { xs: "column", sm: "row" } }}
            >
              <Typography variant="h1" sx={{ mb: 3 }}>
                {t("dogRegistration")}
              </Typography>
              <Typography variant="body" sx={{ mr: 2, mt: 1, float: "right" }}>
                {t("mandatoryFields")}
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={12}>
            <Stepper
              activeStep={activeStep}
              sx={{
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "flex-start", sm: "center" },
              }}
            >
              <Step key="1">
                <StepLabel>{t("registrationForm")}</StepLabel>
              </Step>
              <Step key="2">
                <StepLabel>{t("attachments")}</StepLabel>
              </Step>
              <Step key="3">
                <StepLabel>{t("summary")}</StepLabel>
              </Step>
            </Stepper>
          </Grid>
          <Grid xs={12}>{CurrentStep()}</Grid>
          <Grid xs={12}>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t("previous")}
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Box>
                {Errors()}
                {NextButton()}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
