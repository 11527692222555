import React, { createContext, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

// Authentication
import { AuthenticationGuard } from "./auth0";

// Import components
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import { Container, Alert, Snackbar } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { useRecoilState, useRecoilValue } from "recoil";
import { loadingState, snackBarState } from "./recoil/globalStates";

// Local components
import Navbar from "./components/navigation/Navbar";
import Footer from "./components/Footer";
import { LoaderOverlay } from "./elements/Loader";
import { GlobalError } from "./elements/alerts";

// Public Pages
import DogList from "./pages/listViews/dogs";
import BreederList from "./pages/listViews/breeders";
import LitterList from "./pages/listViews/litters";
import DogProfile from "./pages/dogProfile";
import BreederProfile from "./pages/breederProfile";
import LitterProfile from "./pages/litterProfile";
import Home from "./pages/home";
import Search from "./components/search";

// Private Pages
import UserProfile from "./pages/userProfile";
import MyDogs from "./pages/usersOwnedDogs";
import BredDogs from "./pages/usersBredDogs";
import DogRegistration from "./pages/dogRegistration/dogRegistration";
import Registrations from "./pages/usersRegistrations";
import Verify from "./pages/verificationPage";
import LitterRegistrationContainer from "./pages/litterRegistration/litterRegistrationContainer";
import LitterPaymentSuccess from "./pages/litterRegistration/paymentSuccess";
import DogPaymentSuccess from "./pages/dogRegistration/paymentSuccess";

// Admin Pages
import AdminDashboard from "./pages/adminDashboard/views/dashboard/dashboard";
import AdminAllDogs from "./pages/adminDashboard/views/allDogs";
import AdminView from "./pages/adminDashboard/views/container";
import AdminDogView from "./pages/adminDashboard/views/dog";
import AdminTempDogView from "./pages/adminDashboard/views/tempdog";
import AdminAllTempDogs from "./pages/adminDashboard/views/allTempDogs";
import AdminAllUsers from "./pages/adminDashboard/views/allUsers";
import AdminUserView from "./pages/adminDashboard/views/user";
import AdminAllTempLitters from "./pages/adminDashboard/views/allTemplitters";
import AdminLitterView from "./pages/adminDashboard/views/litter";
import AdminAllLitters from "./pages/adminDashboard/views/allLitters";
import AdminTempLitterView from "./pages/adminDashboard/views/templitter";
import AdminAllHealthResults from "./pages/adminDashboard/views/allHealthResults";
import AdminHealthResultView from "./pages/adminDashboard/views/healthresult";
import AdminAllGeneticResults from "./pages/adminDashboard/views/allGeneticResults";
import AdminGeneticResultView from "./pages/adminDashboard/views/geneticresult";
import AdminCompetitionResultView from "./pages/adminDashboard/views/competitionresult";
import AdminAllCompetitionResults from "./pages/adminDashboard/views/allCompetitionResults";
import AdminAllTreedogs from "./pages/adminDashboard/views/allTreedogs";
import AdminTreedogView from "./pages/adminDashboard/views/treedog";
import AdminAllOrganizations from "./pages/adminDashboard/views/allOrganizations";
import AdminOrganizationView from "./pages/adminDashboard/views/organization";
import AdminAllCertificates from "./pages/adminDashboard/views/allCertificates";
import AdminCertificateView from "./pages/adminDashboard/views/certificate";
import AdminAllResultproviders from "./pages/adminDashboard/views/allResultproviders";
import AdminResultproviderView from "./pages/adminDashboard/views/resultprovider";
import AdminPedigreeTool from "./pages/adminDashboard/views/pedigreeTool";

// Contexts
export const UserContext = createContext();
export const KruValueContext = createContext();

export default function App() {
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const loading = useRecoilValue(loadingState);

  const logError = (error) => {
    setSnackbarState({
      message: error.message,
      severity: "error",
      open: true,
    });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarState.open}
        onClose={() => setSnackbarState({ ...snackBarState, open: false })}
      >
        <Alert
          severity={snackbarState.severity}
          onClose={() => setSnackbarState({ ...snackBarState, open: false })}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
      <Navbar />
      <ErrorBoundary FallbackComponent={GlobalError} onError={logError}>
        <Suspense fallback={<LoaderOverlay />}>
          <Container
            disableGutters
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 0 } }}
          >
            {loading && <LoaderOverlay />}
            <Routes>
              {/* PUBLIC ROUTES FI */}
              <Route path="/" element={<Home />} />
              <Route path="/haku" element={<Search />} />
              <Route path="/selaa" element={<DogList />} />
              <Route path="/kasvattajat" element={<BreederList />} />
              <Route path="/pentueet" element={<LitterList />} />
              <Route path="/koira/:id" element={<DogProfile />} />
              <Route path="/kasvattaja/:id" element={<BreederProfile />} />
              <Route path="/pentue/:id" element={<LitterProfile />} />
              {/* PUBLIC ROUTES EN */}
              <Route path="/search" element={<Search />} />
              <Route path="/dogs" element={<DogList />} />
              <Route path="/breeders" element={<BreederList />} />
              <Route path="/litters" element={<LitterList />} />
              <Route path="/dog/:id" element={<DogProfile />} />
              <Route path="/breeder/:id" element={<BreederProfile />} />
              <Route path="/litter/:id" element={<LitterProfile />} />
              {/* PRIVATE ROUTES */}
              <Route
                path="/user"
                element={<AuthenticationGuard component={UserProfile} />}
              />
              <Route
                path="/mydogs"
                element={<AuthenticationGuard component={MyDogs} />}
              />
              <Route
                path="/mylitters"
                element={<AuthenticationGuard component={BredDogs} />}
              />
              <Route
                path="/litter-registration/:id?"
                element={
                  <AuthenticationGuard
                    component={LitterRegistrationContainer}
                  />
                }
              />
              <Route
                path="/litter-registration/:id/success"
                element={
                  <AuthenticationGuard component={LitterPaymentSuccess} />
                }
              />
              <Route
                path="/dog-registration/:id?"
                element={<AuthenticationGuard component={DogRegistration} />}
              />
              <Route
                path="/dog-registration/:id/success"
                element={<AuthenticationGuard component={DogPaymentSuccess} />}
              />
              <Route
                path="/registrations"
                element={<AuthenticationGuard component={Registrations} />}
              />
              <Route
                path="/verify/:_id"
                element={<AuthenticationGuard component={Verify} />}
              />
              {/* ADMIN DASHBOARD */}
              <Route
                path="/admin"
                element={<AuthenticationGuard component={AdminView} />}
              >
                <Route
                  path="/admin/dashboard"
                  element={<AuthenticationGuard component={AdminDashboard} />}
                />
                {/* Admin Listing views */}
                <Route
                  path="/admin/alldogs"
                  element={<AuthenticationGuard component={AdminAllDogs} />}
                />
                <Route
                  path="/admin/alltempdogs"
                  element={<AuthenticationGuard component={AdminAllTempDogs} />}
                />
                <Route
                  path="/admin/allusers"
                  element={<AuthenticationGuard component={AdminAllUsers} />}
                />
                <Route
                  path="/admin/alllitters"
                  element={<AuthenticationGuard component={AdminAllLitters} />}
                />
                <Route
                  path="/admin/alltemplitters"
                  element={
                    <AuthenticationGuard component={AdminAllTempLitters} />
                  }
                />
                <Route
                  path="/admin/allhealthresults"
                  element={
                    <AuthenticationGuard component={AdminAllHealthResults} />
                  }
                />
                <Route
                  path="/admin/allgeneticresults"
                  element={
                    <AuthenticationGuard component={AdminAllGeneticResults} />
                  }
                />
                <Route
                  path="/admin/allcompetitionresults"
                  element={
                    <AuthenticationGuard
                      component={AdminAllCompetitionResults}
                    />
                  }
                />
                <Route
                  path="/admin/alltreedogs"
                  element={<AuthenticationGuard component={AdminAllTreedogs} />}
                />
                <Route
                  path="/admin/allorganizations"
                  element={
                    <AuthenticationGuard component={AdminAllOrganizations} />
                  }
                />
                <Route
                  path="/admin/allcertificates"
                  element={
                    <AuthenticationGuard component={AdminAllCertificates} />
                  }
                />
                <Route
                  path="/admin/allresultproviders"
                  element={
                    <AuthenticationGuard component={AdminAllResultproviders} />
                  }
                />
                {/* Admin Single document views */}
                <Route
                  path="/admin/dog/:id"
                  element={<AuthenticationGuard component={AdminDogView} />}
                />
                <Route
                  path="/admin/tempdog/:id"
                  element={<AuthenticationGuard component={AdminTempDogView} />}
                />
                <Route
                  path="/admin/litter/:id"
                  element={<AuthenticationGuard component={AdminLitterView} />}
                />
                <Route
                  path="/admin/templitter/:id"
                  element={
                    <AuthenticationGuard component={AdminTempLitterView} />
                  }
                />
                <Route
                  path="/admin/user/:id"
                  element={<AuthenticationGuard component={AdminUserView} />}
                />
                <Route
                  path="/admin/healthresult/:id"
                  element={
                    <AuthenticationGuard component={AdminHealthResultView} />
                  }
                />
                <Route
                  path="/admin/geneticresult/:id"
                  element={
                    <AuthenticationGuard component={AdminGeneticResultView} />
                  }
                />
                <Route
                  path="/admin/competitionresult/:id"
                  element={
                    <AuthenticationGuard
                      component={AdminCompetitionResultView}
                    />
                  }
                />
                <Route
                  path="/admin/treedog/:id"
                  element={<AuthenticationGuard component={AdminTreedogView} />}
                />

                <Route
                  path="/admin/organization/:id"
                  element={
                    <AuthenticationGuard component={AdminOrganizationView} />
                  }
                />
                <Route
                  path="/admin/certificate/:id"
                  element={
                    <AuthenticationGuard component={AdminCertificateView} />
                  }
                />
                <Route
                  path="/admin/resultprovider/:id"
                  element={
                    <AuthenticationGuard component={AdminResultproviderView} />
                  }
                />
                <Route
                  path="/admin/pedigreetool/:id?"
                  element={
                    <AuthenticationGuard component={AdminPedigreeTool} />
                  }
                />
              </Route>
            </Routes>
            <ScrollUpButton
              style={{
                backgroundColor: "#004569cf",
                fill: "#ffffff",
                height: "45px",
                width: "45px",
                paddingTop: "8px",
                paddingBottom: "5px",
                paddingLeft: "10px",
                paddingRight: "10px",
                borderRadius: "50px",
              }}
            />
          </Container>
          <Footer />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
