import React from "react";

import OrganizationForm from "../forms/organizationForm";

import { useRecoilValue } from "recoil";
import { selectedDocByIdState } from "../../../recoil/adminStates";
import { AdminNotFound } from "../../../components/adminDashboardComponents/adminNotFound";

function AdminOrganizationView() {
  const org = useRecoilValue(selectedDocByIdState("organization"));

  return org ? (
    <OrganizationForm action="edit" org={org} />
  ) : (
    <AdminNotFound docType="Organization" />
  );
}

export default AdminOrganizationView;
