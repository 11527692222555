import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

// API
import { getCountryByCode, getKruValueLabel } from "../../constants/helpers";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Paper,
  Typography,
  Box,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer,
} from "@mui/material";
import { CheckCircleOutlined } from "@mui/icons-material";

import { useRecoilValue } from "recoil";
import { tempDogByIdState } from "../../recoil/privateStates";

export default function DogPaymentSuccess() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const dog = useRecoilValue(tempDogByIdState(id));

  const language = i18n.language;

  return (
    <>
      <Paper sx={{ p: 4 }}>
        <Grid container alignItems="center" spacing={6}>
          <Grid item xs={12} sm={9}>
            <Typography variant="h2" sx={{ mb: 2 }}>
              {t("paymentSuccessfulHeader")}
            </Typography>
            <Typography variant="body">{t("paymentSuccessfulText")}</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display="flex" justifyContent="center">
              <CheckCircleOutlined
                color="primary"
                sx={{
                  fontSize: { xs: "80px", sm: "100px" },
                  textAlign: "center",
                }}
              />
            </Box>
          </Grid>
          {dog && (
            <Grid item xs={12}>
              <Typography variant="h3">{t("summary")}</Typography>
              <TableContainer>
                <Table sx={{ minWidth: 200 }}>
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">{t("registry")}</TableCell>
                      <TableCell>{dog.organizations[0]?.name || ""}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("dogsName")}</TableCell>
                      <TableCell>{dog.name || ""}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">
                        {t("microchipNumber")}
                      </TableCell>
                      <TableCell>{dog.microchipNumber || ""}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("dob")}</TableCell>
                      <TableCell>
                        {dayjs(dog.dateOfBirth).format("DD.MM.YYYY") || ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("sex")}</TableCell>
                      <TableCell>{t(dog.sex) || ""}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">
                        {t("countryOfOrigin")}
                      </TableCell>
                      <TableCell>
                        {getCountryByCode(dog.countryOfOrigin, language) || ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("coat")}</TableCell>
                      <TableCell>
                        {dog.coat?.map((value, i) => {
                          return (
                            getKruValueLabel(value, language) +
                            (dog.coat.length - 1 === i ? "" : ", ")
                          );
                        }) || " "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("color")}</TableCell>
                      <TableCell>
                        {dog.color?.map((value, i) => {
                          return (
                            getKruValueLabel(value, language) +
                            (dog.color.length - 1 === i ? "" : ", ")
                          );
                        }) || " "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("height")}</TableCell>
                      <TableCell>
                        {dog.height || "-"}
                        {" cm"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("weight")}</TableCell>
                      <TableCell>
                        {dog.weight || "-"}
                        {" kg"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  );
}
