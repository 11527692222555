import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { getUserById } from "../../api/adminRoutes";
import { LoaderOverlay } from "../../elements/Loader";
import { useRecoilValue } from "recoil";
import { accessTokenState } from "../../recoil/globalStates";

export default function VerificationsList({ verifications }) {
  const accessToken = useRecoilValue(accessTokenState);
  const [loading, setLoading] = useState(true);
  const [populatedVerifications, setPopulatedVerifications] = useState(true);

  useEffect(() => {
    if (!accessToken) return;
    setLoading(true);

    // fetch verification user info for each verification object
    const populateUsers = async () => {
      let populated = [];
      for (const verification of verifications) {
        populated.push({
          ...verification,
          user: await getUserById(verification.user, { accessToken }),
        });
      }
      setPopulatedVerifications(populated);
    };
    populateUsers();

    setLoading(false);
  }, [verifications]);

  return (
    <>
      {loading && <LoaderOverlay />}
      {populatedVerifications && !loading && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Operation</TableCell>
                <TableCell>Verified?</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell>_id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {populatedVerifications.length > 0 ? (
                populatedVerifications.map((verification, i) => (
                  <TableRow key={i}>
                    <TableCell>{verification.operation}</TableCell>
                    <TableCell>
                      {verification.verified ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {verification.user?.name} {`(${verification.user.email})`}
                    </TableCell>
                    <TableCell>
                      {dayjs(verification?.createdAt).format("DD.MM.YYYY")}
                    </TableCell>
                    <TableCell>
                      {dayjs(verification?.updatedAt).format("DD.MM.YYYY")}
                    </TableCell>
                    <TableCell>{verification._id}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    No verifications
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
