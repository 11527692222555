import React, { useState } from "react";

import { Grid, TextField } from "@mui/material";

import {
  createNewResultprovider,
  deleteResultprovider,
  updateResultprovider,
} from "../../../api/adminRoutes";

import { accessTokenState, snackBarState } from "../../../recoil/globalStates";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import FormHeader from "../../../components/adminDashboardComponents/formHeader";
import JsonPreviewWidget from "../../../components/adminDashboardComponents/jsonPreviewWidget";
import FormButtons from "../../../components/adminDashboardComponents/formButtons";

function ResultproviderForm({ action, provider, handleModalClose }) {
  const { t } = useTranslation();
  const accessToken = useRecoilValue(accessTokenState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const [initialProvider, setInitialProvider] = useState(provider);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm();

  const formdata = watch();

  const onSubmit = (data) => {
    if (action === "add") {
      createNewResultprovider(data, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setSnackbarState({
            message: t("creationSuccess", { docType: "Result provider" }),
            severity: "success",
            open: true,
          });

          window.location.reload(true);
        }
      });
    }
    if (action === "edit") {
      updateResultprovider(initialProvider._id, data, { accessToken }).then(
        (res) => {
          if (res.error) {
            setSnackbarState({
              message: res.message,
              severity: "error",
              open: true,
            });
          } else {
            setSnackbarState({
              message: t("updateSuccess", { docType: "Result provider" }),
              severity: "success",
              open: true,
            });
            setTimeout(() => {
              window.location.reload(true);
            }, 1000);
          }
        }
      );
    }
  };

  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete this result provider? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteResultprovider(initialProvider._id, { accessToken }).then(
          (res) => {
            if (res.error) {
              setSnackbarState({
                message: res.message,
                severity: "error",
                open: true,
              });
            } else {
              alert(`Result provider has been removed.`);
              setTimeout((window.location = `/admin/allresultproviders`), 3000);
            }
          }
        );
      };
      deleteData();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          spacing={3}
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <FormHeader
              action={action}
              formType="resultprovider"
              data={initialProvider}
              handleModalClose={handleModalClose}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="name"
              control={control}
              defaultValue={initialProvider?.name || ""}
              rules={{ required: "Required", minLength: 1 }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Name of the provider"
                  error={errors?.name}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="url"
              defaultValue={initialProvider?.url || ""}
              control={control}
              render={({ field: { ref, name, ...field } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Result provider's website URL"
                  helperText="Include https:// in the url"
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <JsonPreviewWidget formdata={formdata} />
          </Grid>

          <Grid item xs={12}>
            <FormButtons
              action={action}
              handleDelete={handleDelete}
              isSubmitting={isSubmitting}
              isDirty={isDirty}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
export default ResultproviderForm;
