import React, { useState } from "react";

import {
  Grid,
  TextField,
  Select,
  FormControl,
  MenuItem,
  Autocomplete,
  InputLabel,
  FormHelperText,
} from "@mui/material";

import {
  createNewCertificate,
  deleteCertificate,
  updateCertificate,
} from "../../../api/adminRoutes";

import { accessTokenState, snackBarState } from "../../../recoil/globalStates";
import { adminAllOrgsState } from "../../../recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import FormHeader from "../../../components/adminDashboardComponents/formHeader";
import JsonPreviewWidget from "../../../components/adminDashboardComponents/jsonPreviewWidget";
import FormButtons from "../../../components/adminDashboardComponents/formButtons";

function CertificateForm({ action, cert, handleModalClose }) {
  const { t } = useTranslation();
  const accessToken = useRecoilValue(accessTokenState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const organizations = useRecoilValue(adminAllOrgsState);

  const [initialCert, setInitialCert] = useState(cert);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm();

  const formdata = watch();

  const onSubmit = (data) => {
    if (action === "add") {
      createNewCertificate(data, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setSnackbarState({
            message: t("creationSuccess", { docType: "Certificate" }),
            severity: "success",
            open: true,
          });

          window.location.reload(true);
        }
      });
    }
    if (action === "edit") {
      updateCertificate(initialCert._id, data, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setSnackbarState({
            message: t("updateSuccess", { docType: "Certificate" }),
            severity: "success",
            open: true,
          });
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      });
    }
  };

  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete this certificate? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteCertificate(initialCert._id, { accessToken }).then(
          (res) => {
            if (res.error) {
              setSnackbarState({
                message: res.message,
                severity: "error",
                open: true,
              });
            } else {
              alert(`Certificate has been removed.`);
              setTimeout((window.location = `/admin/allcertificates`), 3000);
            }
          }
        );
      };
      deleteData();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          spacing={3}
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <FormHeader
              action={action}
              formType="certificate"
              data={initialCert}
              handleModalClose={handleModalClose}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="name"
              control={control}
              defaultValue={initialCert?.name || ""}
              rules={{ required: "Name is required", minLength: 1 }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Name of certificate"
                  error={errors?.name}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Type *</InputLabel>
              <Controller
                name="type"
                control={control}
                defaultValue={initialCert?.type || ""}
                rules={{
                  minLength: 1,
                  required: "Required",
                }}
                render={({ field: { ref, name, ...field } }) => (
                  <Select {...field} fullWidth required label="Type">
                    <MenuItem key={0} value="dog">
                      Dog
                    </MenuItem>
                    <MenuItem key={1} value="breeder">
                      Breeder
                    </MenuItem>
                  </Select>
                )}
              />
              <FormHelperText>{errors?.type?.message}</FormHelperText>
              <FormHelperText>To whom the certificate is issued</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Controller
                name="issuerOrg"
                onChange={([, data]) => data}
                defaultValue={initialCert?.issuerOrg || null}
                control={control}
                rules={{
                  required: "Required",
                }}
                render={({ field: { onChange, ...props } }) => (
                  <Autocomplete
                    fullWidth
                    freeSolo
                    required
                    autoComplete={false}
                    label="Issuer organization"
                    options={organizations}
                    isOptionEqualToValue={(option, value) =>
                      option._id == value._id
                    }
                    getOptionLabel={(option) => `${option.name}`}
                    onChange={(e, data) => onChange(data)}
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        required
                        label="Issuer organization"
                        error={errors?.issuerOrg}
                        helperText={
                          errors?.issuerOrg && errors?.issuerOrg?.message
                        }
                      />
                    )}
                    {...props}
                  />
                )}
              />
              <FormHelperText>
                The organization who issues the certificates
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="url"
              defaultValue={initialCert?.url || ""}
              control={control}
              render={({ field: { ref, name, ...field } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Certificate URL"
                  helperText="Include https:// in the url"
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <JsonPreviewWidget formdata={formdata} />
          </Grid>

          <Grid item xs={12}>
            <FormButtons
              action={action}
              handleDelete={handleDelete}
              isSubmitting={isSubmitting}
              isDirty={isDirty}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
export default CertificateForm;
