import React, { useEffect, useState } from "react";
import { getAttachments } from "../../api/adminRoutes";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faDownload } from "@fortawesome/free-solid-svg-icons";
import { adminUrl, request } from "../../api/common";
import { useRecoilValue } from "recoil";
import { accessTokenState } from "../../recoil/globalStates";

const iconStyle = {
  color: "#0000008a",
  fontSize: "1.2rem",
};

function handleAttachment(accessToken, attachment, action = "view") {
  const url = adminUrl([`/attachments/byId/${attachment._id}/file`]);
  request({ url, method: "GET", accessToken, raw: true })
    .then((response) => response.blob())
    .then((blob) => {
      // Create a objectURL from the binary file
      const file = window.URL.createObjectURL(blob);

      // Create a fake link tag so we can click it later.
      // Believe or not this is the intended way of making the browser download a file if it's not available by URL.
      const fakeLink = document.createElement("a");
      fakeLink.href = file;

      // handle downloading or viewing the file
      switch (action) {
        case "view":
          fakeLink.target = "_blank";
          break;
        case "download":
          const filename = `${attachment.category}-${attachment.parentDoc}-${attachment.originalName}`;
          fakeLink.download = filename;
          break;
        default:
          break;
      }

      // fakeLink must be appended to the DOM or it can't be clicked
      document.body.appendChild(fakeLink);
      fakeLink.click();
      fakeLink.remove();
    });
}

export default function AttachmentList({ parentDoc }) {
  const parentId = parentDoc._id;
  const accessToken = useRecoilValue(accessTokenState);

  const [loading, setLoading] = useState(true);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    getAttachments({ accessToken, query: { parentDoc: parentId } }).then(
      (data) => {
        setAttachments(data);
        setLoading(false);
      }
    );
  }, [parentId]);

  if (loading) return;
  <Typography variant="body">Loading attachments...</Typography>;

  if (!attachments.length) {
    return <Typography variant="body">No attachments</Typography>;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>File name</TableCell>
            <TableCell>Preview</TableCell>
            <TableCell>Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attachments.map((attachment, i) => (
            <TableRow key={i}>
              <TableCell>{attachment.category}</TableCell>
              <TableCell>{attachment.originalName}</TableCell>
              <TableCell>
                <IconButton
                  onClick={() =>
                    handleAttachment(accessToken, attachment, "view")
                  }
                >
                  <FontAwesomeIcon icon={faEye} style={iconStyle} />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() =>
                    handleAttachment(accessToken, attachment, "download")
                  }
                >
                  <FontAwesomeIcon icon={faDownload} style={iconStyle} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
