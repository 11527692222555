import React, { useState } from "react";

import { getOrganizationColumns } from "../../../components/adminDashboardComponents/adminDatagridColumns";

// Components
import FormModal from "../../../components/adminDashboardComponents/formModal";

// MUI
import { Typography, Grid, Button, Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Add from "@mui/icons-material/Add";
import OrganizationForm from "../forms/organizationForm";

import { adminAllOrgsState } from "../../../recoil/adminStates";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

function AdminAllOrganizations() {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const results = useRecoilValue(adminAllOrgsState);

  const handleRowClick = (params) => {
    window.location = `/admin/organization/${params.row._id}`;
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    if (confirm(t("confirmModalClose"))) {
      setModalOpen(false);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems="center"
            alignContent={"center"}
            spacing={{ xs: 2, sm: 0 }}
          >
            <Typography variant="h2">Organizations</Typography>
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={handleModalOpen}
            >
              Add organization
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <DataGrid
            autoHeight
            pageSize={50}
            onRowClick={handleRowClick}
            rows={results}
            getRowId={(row) => row._id}
            columns={getOrganizationColumns("organization")}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </Grid>
      </Grid>
      <FormModal open={modalOpen} onClose={handleModalClose}>
        <OrganizationForm action="add" handleModalClose={handleModalClose} />
      </FormModal>
    </>
  );
}

export default AdminAllOrganizations;
