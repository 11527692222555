import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";

// MUI
import {
  Button,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Link,
} from "@mui/material";

// MUI Icons
import Logout from "@mui/icons-material/Logout";
import { getDashboardAccess } from "../../constants/helpers";
import { ManageAccounts } from "@mui/icons-material";
import { useRecoilValue } from "recoil";
import { userState } from "../../recoil/globalStates";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: "12px 20px 12px 20px",
}));

function UserNavigation() {
  const { t } = useTranslation();
  const { user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const dbUser = useRecoilValue(userState);
  const canAccessDashboard = getDashboardAccess(dbUser.role);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton sx={{ ml: 2, p: 0 }} onClick={(e) => handleClick(e)}>
        <Avatar src={user?.picture} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <StyledMenuItem
          divider
          component={Button}
          href="/user"
          sx={{ textTransform: "none" }}
        >
          <Avatar src={user?.picture} sx={{ width: 30, height: 30, mr: 1 }} />
          {user?.name || t("profile")}
        </StyledMenuItem>
        {canAccessDashboard && (
          <StyledMenuItem divider component={Link} href="/admin/dashboard">
            <ListItemIcon>
              <ManageAccounts fontSize="medium" />
            </ListItemIcon>
            {t("dashboard")}
          </StyledMenuItem>
        )}

        <StyledMenuItem onClick={() => logout()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("logOut")}
        </StyledMenuItem>
      </Menu>
    </>
  );
}
export default UserNavigation;
