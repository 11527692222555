import React from "react";

// Routes
import { updateLitter } from "../../../api/adminRoutes";

// Components
import LitterForm from "../forms/litterForm";

import { snackBarState } from "../../../recoil/globalStates";
import { selectedDocByIdState } from "../../../recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { AdminNotFound } from "../../../components/adminDashboardComponents/adminNotFound";

function AdminLitterView() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const litter = useRecoilValue(selectedDocByIdState("litter"));

  const handleSubmit = (accessToken, newLitter, id) => {
    updateLitter(id, newLitter, {
      accessToken,
      options: { new: true },
    }).then((response) => {
      if (response.error) {
        setSnackbarState({
          message: res.message,
          severity: "error",
          open: true,
        });
      } else {
        setSnackbarState({
          message: t("updateSuccess", { docType: "Litter" }),
          severity: "success",
          open: true,
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
    });
  };

  return litter ? (
    <LitterForm
      litter={litter}
      formType="litter"
      action="edit"
      emitHandleSubmit={handleSubmit}
    />
  ) : (
    <AdminNotFound docType="Litter" />
  );
}

export default AdminLitterView;
