import React, { Component } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// MUI
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { getKruValueLabel } from "../../constants/helpers";
import {
  getEcvoResultsForUI,
  isFormatSidesOrGrade,
} from "../../constants/healthtestFormat";

export default function HealthInfo({ dog }) {
  const { t, i18n } = useTranslation();
  const results = dog.health;
  const error = t("noData");
  const lang = i18n.language;

  const healthHeaders =
    results && results?.length > 0 ? (
      <TableRow>
        <TableCell>{t("date")}</TableCell>
        <TableCell>{t("subject")}</TableCell>
        <TableCell>{t("result")}</TableCell>
        <TableCell>{t("additionalInfo")}</TableCell>
      </TableRow>
    ) : null;

  const healthMarkup =
    results && results?.length > 0 ? (
      results.map((result, index) => (
        <TableRow key={index}>
          <TableCell>
            {dayjs(result?.date).format("DD.MM.YYYY") || error}
          </TableCell>
          <TableCell>
            {getKruValueLabel(result?.subject, lang) || result?.subject}
          </TableCell>
          <TableCell>
            {isFormatSidesOrGrade(result?.result?.type) === "twoSides"
              ? t(result?.result?.left) + "/" + t(result?.result?.right)
              : isFormatSidesOrGrade(result?.result?.type) === "grade"
              ? t(result?.result?.grade)
              : isFormatSidesOrGrade(result?.result?.type) === "ecvo"
              ? getEcvoResultsForUI(result?.result).map((result, i) => (
                  <p key={i}>{`${t(result[0])}: ${t(result[1])}`}</p>
                ))
              : null}
          </TableCell>
          <TableCell>{result?.additionalInfo || null}</TableCell>
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell colSpan={4}>{t("noResults")}</TableCell>
      </TableRow>
    );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>{healthHeaders}</TableHead>
        <TableBody>{healthMarkup}</TableBody>
      </Table>
    </TableContainer>
  );
}
