import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

import { version } from "../../constants/texts";

// Components
import StyledSearchField from "../../elements/StyledSearchField";
import UserNavigation from "./userNavigation";
import LangPicker from "../LangPicker";
import BrowseMenu from "./mainNavigation/browseMenu";
import ManageMenu from "./mainNavigation/manageMenu";
import RegisterMenu from "./mainNavigation/registerMenu";

// Images
import DatacanisLogo from "../../images/datacanis_logo_w_trim.png";

// MUI
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Stack,
  Divider,
  ListItemIcon,
  useScrollTrigger,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  FormatListBulleted,
  Home,
  ManageSearch,
  Queue,
  Search,
} from "@mui/icons-material";

const returnTo = process.env.REACT_APP_FRONTEND + "/user";

function Navbar() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { t } = useTranslation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const scrollTrigger = useScrollTrigger();

  const browseItems = [
    { label: t("Dogs"), href: "/dogs" },
    { label: t("Breeders"), href: "/breeders" },
    { label: t("Litters"), href: "/litters" },
  ];
  const manageItems = [
    { label: t("myDogs"), href: "/mydogs" },
    { label: t("myLitters"), href: "/mylitters" },
    { label: t("registrations"), href: "/registrations" },
  ];
  const registerItems = [
    { label: t("registerDog"), href: "/dog-registration" },
    { label: t("registerLitter"), href: "/litter-registration" },
  ];

  const handleDrawerToggle = () => {
    if (mobileOpen) {
      setMobileOpen(false);
    } else {
      setMobileOpen(true);
    }
  };

  const drawer = (
    <Box
      onClick={() => handleDrawerToggle()}
      sx={{ textAlign: "center", bgcolor: "secondary.main" }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-around", p: 1 }}>
        <Box
          sx={{ py: 1, height: 42 }}
          component="img"
          src={DatacanisLogo}
          alt="Datacanis logo"
        />
        {!isLoading &&
          (isAuthenticated ? (
            <>
              <UserNavigation />
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              onClick={() => loginWithRedirect({ openUrl: returnTo })}
            >
              {t("login")}
            </Button>
          ))}
      </Box>

      <List sx={{ bgcolor: "white.main", pt: 0 }}>
        <ListItem sx={{ bgcolor: "primary.main" }}>
          <ListItemIcon>
            <ManageSearch color="white" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="h4" sx={{ color: "#fff" }}>
                {t("browse")}
              </Typography>
            }
          />
        </ListItem>
        <Divider />
        {browseItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton href={item.href}>
              <ListItemText
                inset
                primary={<Typography variant="body">{item.label}</Typography>}
              />
            </ListItemButton>
          </ListItem>
        ))}

        <ListItem sx={{ bgcolor: "primary.main" }}>
          <ListItemIcon>
            <FormatListBulleted color="white" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="h4" sx={{ color: "#fff" }}>
                {t("manage")}
              </Typography>
            }
          />
        </ListItem>
        <Divider />
        {manageItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton href={item.href}>
              <ListItemText
                inset
                primary={<Typography variant="body">{item.label}</Typography>}
              />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem sx={{ bgcolor: "primary.main" }}>
          <ListItemIcon>
            <Queue color="white" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="h4" sx={{ color: "#fff" }}>
                {t("register")}
              </Typography>
            }
          />
        </ListItem>
        <Divider />
        {registerItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton href={item.href}>
              <ListItemText
                inset
                primary={<Typography variant="body">{item.label}</Typography>}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar color="secondary" position={scrollTrigger ? "fixed" : "sticky"}>
        <Toolbar disableGutters sx={{ pl: 1, pr: 2 }}>
          <Stack direction="row" alignItems="baseline" flexGrow={1}>
            <IconButton href="/">
              <Box
                sx={{ height: { xs: 20, md: 24 } }}
                component="img"
                src={DatacanisLogo}
                alt="Datacanis logo"
              />
            </IconButton>

            <Typography
              variant="caption"
              sx={{ flexGrow: 1, mr: 2, display: { xs: "none", sm: "flex" } }}
            >
              {version}
            </Typography>
            <Box>
              <LangPicker />
            </Box>
          </Stack>

          <Box
            sx={{
              flexGrow: 2,
              justifyContent: "center",
              display: { xs: "none", md: "block" },
            }}
          >
            <StyledSearchField />
          </Box>
          <Box
            sx={{
              flexGrow: 2,
              justifyContent: "flex-end",
              display: { md: "none" },
            }}
          >
            <IconButton href="/haku/?hakusanat=" sx={{ mr: 2 }}>
              <Search color="white" />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="open"
              edge="start"
              onClick={(e) => handleDrawerToggle(e)}
              sx={{ mr: 2, float: "right", display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              flexGrow: 2,
              justifyContent: "flex-end",
              display: { xs: "none", sm: "flex" },
            }}
          >
            <Button
              color="white"
              onClick={() => (window.location = "/")}
              startIcon={<Home />}
            >
              {t("Home")}
            </Button>
            <BrowseMenu />
            <ManageMenu />
            <RegisterMenu />
            {!isLoading &&
              (isAuthenticated ? (
                <>
                  <UserNavigation />
                </>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: 2 }}
                  onClick={() => loginWithRedirect()}
                >
                  {t("login")}
                </Button>
              ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          anchor="top"
          open={mobileOpen}
          onClose={() => handleDrawerToggle()}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
export default Navbar;
