import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import { registries } from "../../../constants/registries";
import { countries } from "../../../constants/countries";

// Routes
import {
  acceptTempDog,
  addProfilePictureById,
  deleteDog,
  deleteTempDog,
} from "../../../api/adminRoutes";

// Components
import BreedTestComponent from "./breedtestForm";
import FormModal from "../../../components/adminDashboardComponents/formModal";

// MUI
import {
  Grid,
  TextField,
  FormControl,
  Divider,
  Typography,
  Select,
  MenuItem,
  Autocomplete,
  InputLabel,
  Checkbox,
  ListItem,
  List,
  ListItemText,
  Button,
  InputAdornment,
  ListSubheader,
  Stack,
  IconButton,
  FormControlLabel,
  ListItemButton,
  ListItemIcon,
  FormHelperText,
  Alert,
  AlertTitle,
  Paper,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Delete, Add, Launch } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import AttachmentList from "../../../components/formComponents/attachmentList";
import { constSelector, useRecoilState, useRecoilValue } from "recoil";
import {
  accessTokenState,
  kruValuesState,
  loadingState,
  snackBarState,
  userState,
} from "../../../recoil/globalStates";
import {
  adminAllCertsState,
  adminAllOrgsState,
  adminAllUsersState,
  adminDogsByGenderState,
  adminGetBreederByIdState,
  adminGetOwnersByIdState,
} from "../../../recoil/adminStates";
import AcceptButton from "../../../components/adminDashboardComponents/formRegistrationAcceptButton";
import FormHeader from "../../../components/adminDashboardComponents/formHeader";
import JsonPreviewWidget from "../../../components/adminDashboardComponents/jsonPreviewWidget";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import FormButtons from "../../../components/adminDashboardComponents/formButtons";
import { useAuth0 } from "@auth0/auth0-react";
import { kuvaServer } from "../../../constants/values";
import defaultImgUrl from "../../../images/placeholder.png";

// This form is also used for tempDogs
function DogForm({
  dog,
  formType,
  action,
  emitHandleSubmit,
  handleModalClose,
}) {
  const { isAuthenticated } = useAuth0();
  const currentUser = useRecoilValue(
    isAuthenticated ? userState : constSelector(null)
  );

  const { t, i18n } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const accessToken = useRecoilValue(accessTokenState);
  const kruValues = useRecoilValue(kruValuesState);
  const users = useRecoilValue(adminAllUsersState);

  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [loadingOverlayState, setLoadingOverlayState] =
    useRecoilState(loadingState);

  const [initialDog, setInitialDog] = useState(action === "edit" ? dog : null);

  // fetch initial breeder data with breeder's id
  const [initBreeder, setInitBreeder] = useState(
    useRecoilValue(
      dog?.breeder ? adminGetBreederByIdState(dog.breeder) : constSelector(null)
    )
  );

  // fetch initial owners data with owners' ids
  const [initOwners, setInitOwners] = useState(
    useRecoilValue(
      dog?.owners ? adminGetOwnersByIdState(dog.owners) : constSelector([])
    )
  );

  const [hiddenOwners, sethiddenOwners] = useState(null);

  const imgUrl = initialDog?.hasPhoto
    ? kuvaServer + initialDog._id + ".jpg"
    : defaultImgUrl;

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm();

  const {
    append: appendRegOther,
    fields: regOtherFields,
    remove: removeRegOther,
  } = useFieldArray({ control, name: "regOther" });

  const {
    append: appendCerts,
    fields: certFields,
    remove: removeCerts,
  } = useFieldArray({ control, name: "certificates" });

  const formdata = watch();
  const isDeceased = formdata.isDeceased;
  const regType = formdata.regType;
  const regOther = formdata.regOther;
  const owners = formdata.owners;
  const breeder = formdata.breeder;
  const certificates = formdata.certificates;

  // set the regOther array from the initial dog since useFieldArray doesnt allow
  // setting a default and useForm defaults dont allow variables as defaults
  useEffect(() => {
    setValue("regOther", initialDog?.regOther);
    setValue("certificates", initialDog?.certificates);

    // Count how many hidden owners the dog has if any
    if (owners) {
      let hiddenOwners = 0;
      owners.forEach((owner) => {
        owner === "hidden" && hiddenOwners++;
      });
      sethiddenOwners(hiddenOwners);
    }
  }, [initialDog]);

  const femaleDogs = useRecoilValue(adminDogsByGenderState("female"));
  const maleDogs = useRecoilValue(adminDogsByGenderState("male"));

  const organizations = useRecoilValue(adminAllOrgsState);
  const [dogsOrgs, setDogsOrgs] = useState(dog?.organizations || []);

  const [selectedCountry, setSelectedCountry] = useState(
    dog?.countryOfOrigin
      ? countries.find((c) => c.code === dog.countryOfOrigin.toUpperCase())
      : countries.find((c) => c.code === i18n.language.toUpperCase())
  );
  const [selectedSire, setSelectedSire] = useState(dog?.sire || null);
  const [selectedDam, setSelectedDam] = useState(dog?.dam || null);

  const allCertificates = useRecoilValue(adminAllCertsState);

  // add new object to regOther array in the form state
  const addRegOther = () => {
    appendRegOther({
      regNumber: "",
      regBreed: "",
      regRegistry: "",
    });
  };
  // add new object to certificates array in the form state
  const addCerts = () => {
    appendCerts({
      certificate: "",
      issuingDate: dayjs().toISOString(),
    });
  };

  // Open breed test modal
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  // Close breed test modal
  const handleBreedtestModalClose = () => {
    setModalOpen(false);
  };

  const handleFileChange = (event) => {
    const formdata = new FormData();
    formdata.append("image", event.target.files[0]);

    addProfilePictureById(initialDog._id, formdata, {
      accessToken,
    }).then((res) => {
      setLoadingOverlayState(true);
      if (res.error) {
        setSnackbarState({
          message: res.message,
          severity: "error",
          open: true,
        });
      } else {
        setSnackbarState({
          message: t("updateSuccess", { docType: "Dog's profile picture" }),
          severity: "success",
          open: true,
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
    });
  };

  // Handle form submit, call the parent components handleSubmit function that send the document to API patch
  const onSubmit = (data) => {
    // change country object to country code before sending
    data.countryOfOrigin = data.countryOfOrigin.code;
    // map each object as _id before sending it to API
    if (data.owners) {
      data.owners = data.owners.map((owner) => owner._id);
    }
    data.breeder = data.breeder?._id;
    data.sire = data.sire?._id;
    data.dam = data.dam?._id;

    emitHandleSubmit(accessToken, data, initialDog?._id);
  };

  // Handle deletion of a dog/tempdog, prompt for confirm before the api call is done,
  // after removal, redirect user to listing
  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete ${initialDog.name} ? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteDog(initialDog._id, { accessToken }).then((res) => {
          if (res.error) {
            throw res;
          } else {
            alert(`${res.name} has been removed.`);
            window.location = `/admin/alldogs`;
          }
        });
      };
      const deleteTempData = async () => {
        await deleteTempDog(initialDog._id, { accessToken }).then((res) => {
          if (res.error) {
            throw res;
          } else {
            alert(`${res.name} has been removed.`);
            window.location = `/admin/alltempdogs`;
          }
        });
      };
      if (formType === "dog") {
        deleteData();
      } else if (formType === "tempdog") {
        deleteTempData();
      } else {
        throw { error: true, message: "Error in DogForm Formtype" };
      }
    } else {
      alert(`Cancelled deletion`);
    }
  };

  const handleAccept = () => {
    if (
      confirm(
        "Are you sure you want to accept this dog? Dog is going to be published in the public database."
      )
    ) {
      acceptTempDog(initialDog._id, { accessToken }).then((res) => {
        if (res.error) {
          throw res;
        } else {
          alert("Dog has been accepted and transferred to public database.");
          window.location.replace(`/admin/all${formType}s`);
        }
      });
    } else {
      alert(`Cancelled accepting dog`);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" spacing={3} alignItems="flex-start">
          <Grid item xs={12}>
            <FormHeader
              action={action}
              formType={formType}
              data={initialDog}
              handleModalClose={handleModalClose}
            />
            <AcceptButton
              action={action}
              formType={formType}
              registrationStatus={initialDog?.registrationStatus}
              handleAccept={handleAccept}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="right" sx={{ mb: 2 }}>
              <Typography variant="h5">
                {initialDog?.regNumber || "No reg. no"}
              </Typography>
            </Divider>
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="name"
              control={control}
              defaultValue={initialDog?.name || ""}
              rules={{
                required: "Required",
                minLength: 1,
                maxLength: { value: 100, message: "Max 100 characters" },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Dog's name"
                  error={errors?.name}
                  helperText={errors?.name && errors?.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="microchipNumber"
              control={control}
              defaultValue={initialDog?.microchipNumber || ""}
              rules={{
                required: "Required",
                minLength: 1,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Microchip number"
                  error={errors?.microchipNumber}
                  helperText={
                    errors?.microchipNumber && errors?.microchipNumber?.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="dateOfBirth"
              control={control}
              defaultValue={initialDog?.dateOfBirth || null}
              rules={{
                required: "Required",
              }}
              render={({ field: { ref, name, ...field } }) => (
                <DatePicker
                  {...field}
                  required
                  inputRef={ref}
                  label="Date of birth"
                  onChange={(e) => field.onChange(dayjs(e).toISOString())}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      name={name}
                      error={errors?.dateOfBirth && true}
                      helperText={errors && errors?.dateOfBirth?.message}
                    />
                  )}
                  disableFuture
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Sex *</InputLabel>
              <Controller
                name="sex"
                control={control}
                defaultValue={initialDog?.sex || ""}
                rules={{
                  minLength: 1,
                  required: "Required",
                }}
                render={({ field: { ref, name, ...field } }) => (
                  <Select {...field} fullWidth required label="Sex">
                    <MenuItem value="female">female</MenuItem>
                    <MenuItem value="male">male</MenuItem>
                  </Select>
                )}
              />
              <FormHelperText error={errors?.sex && true}>
                {errors?.sex?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="countryOfOrigin"
              rules={{
                required: "Required",
              }}
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  required
                  label="Country of origin"
                  options={countries}
                  isOptionEqualToValue={(option, value) => option.code == value}
                  getOptionLabel={(option) => option["en"]}
                  renderOption={(props, option) => (
                    <li {...props} key={option.code}>
                      {option["en"]} {option.code}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Country of origin"
                      error={errors?.countryOfOrigin}
                      helperText={
                        errors?.countryOfOrigin &&
                        errors?.countryOfOrigin?.message
                      }
                    />
                  )}
                  onChange={(e, data) => onChange(data)}
                  {...props}
                />
              )}
              onChange={([, data]) => data}
              defaultValue={selectedCountry || null}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Controller
                    name="isDeceased"
                    control={control}
                    defaultValue={initialDog?.isDeceased || false}
                    render={({ field }) => (
                      <Checkbox {...field} checked={field.value} />
                    )}
                  />
                }
                label="Mark as deceased"
              />
            </FormControl>

            {isDeceased && (
              <>
                <Controller
                  name="dateOfDeath"
                  control={control}
                  defaultValue={initialDog?.dateOfDeath || null}
                  render={({ field: { ref, name, ...field } }) => (
                    <DatePicker
                      {...field}
                      inputRef={ref}
                      label="Date of death"
                      onChange={(e) => field.onChange(dayjs(e).toISOString())}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  )}
                />
                <br />
                <br />
                <Controller
                  name="causeOfDeath"
                  control={control}
                  defaultValue={initialDog?.causeOfDeath || ""}
                  render={({ field }) => (
                    <TextField {...field} fullWidth label="Cause of death" />
                  )}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Registration</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="organizations"
              onChange={([, data]) => data}
              defaultValue={dogsOrgs || null}
              control={control}
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  multiple
                  label="Organization"
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.name}
                    </li>
                  )}
                  options={organizations}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(e, data) => onChange(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Organization"
                      helperText={
                        currentUser.role === "admin"
                          ? "Only select one!"
                          : currentUser.role === "manager"
                          ? `NOTE! If ${formType} is removed from your organization, you will lose access to it`
                          : null
                      }
                    />
                  )}
                  {...props}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Registration type *</InputLabel>
              <Controller
                name="regType"
                control={control}
                defaultValue={initialDog?.regType || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field: { ref, name, ...field } }) => (
                  <Select
                    {...field}
                    fullWidth
                    required
                    label="Registration type"
                  >
                    {kruValues
                      .filter((i) => i.category === "reg.types")
                      .map((value) => (
                        <MenuItem key={value._id} value={value._id}>
                          {value.en.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              <FormHelperText error={errors?.regType && true}>
                {errors?.regType?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          {regType === "reg.types.litter" && (
            <Grid item xs={12}>
              <List>
                <ListItem>
                  <Typography variant="h5">Part of Litter</Typography>
                </ListItem>
                {initialDog?.litter ? (
                  <ListItemButton
                    href={`/admin/litter/${initialDog?.litter}`}
                    target="_blank"
                  >
                    <ListItemIcon>
                      <Launch />
                    </ListItemIcon>
                    <ListItemText primary={`${initialDog?.litter}`} />
                  </ListItemButton>
                ) : (
                  <ListItem>{"No litter found"}</ListItem>
                )}
              </List>
            </Grid>
          )}
          {action === "edit" && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5">External registrations</Typography>
              </Grid>
              <Grid item xs={12}>
                {regOtherFields.length > 0 ? (
                  regOtherFields.map((field, i) => (
                    <React.Fragment key={field.id}>
                      <Stack
                        direction={{ xs: "column", md: "row" }}
                        alignItems={"flex-start"}
                        spacing={2}
                        p={2}
                      >
                        <Typography variant="body">{i + 1}.</Typography>

                        <Controller
                          name={`regOther[${i}].regNumber`}
                          control={control}
                          defaultValue={
                            initialDog?.regOther[i]?.regNumber || ""
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label="Registration number"
                            />
                          )}
                        />
                        <Controller
                          name={`regOther[${i}].regBreed`}
                          control={control}
                          defaultValue={regOther[i]?.regBreed || ""}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label="Registered breed or type"
                            />
                          )}
                        />
                        <Controller
                          name={`regOther[${i}].regRegistry`}
                          control={control}
                          defaultValue={regOther[i]?.regRegistry || ""}
                          render={({ field: { onChange, ...props } }) => (
                            <Autocomplete
                              fullWidth
                              freeSolo
                              label="Registry"
                              options={registries}
                              getOptionLabel={(option) => option}
                              onChange={(e, data) => onChange(data)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Registry"
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                              {...props}
                            />
                          )}
                        />
                        <IconButton
                          onClick={() => {
                            if (
                              confirm(
                                "Sure you want to remove this external registry?"
                              )
                            ) {
                              removeRegOther(i);
                            }
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Stack>
                    </React.Fragment>
                  ))
                ) : (
                  <Typography variant="body">No external registries</Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={addRegOther}
                  sx={{ float: "right", mt: 2 }}
                >
                  Add external registry number
                </Button>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Certificates</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            {certFields.length > 0 ? (
              certFields.map((field, i) => (
                <React.Fragment key={field.id}>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    alignItems={"flex-start"}
                    spacing={2}
                    p={2}
                  >
                    <Typography variant="body">{i + 1}.</Typography>

                    <Controller
                      name={`certificates[${i}].certificate`}
                      control={control}
                      defaultValue={certificates[i]?.certificate || ""}
                      render={({ field: { onChange, ...props } }) => (
                        <Autocomplete
                          fullWidth
                          freeSolo
                          label="Certificate"
                          options={allCertificates}
                          isOptionEqualToValue={(option, value) =>
                            option._id == value
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          onChange={(e, data) => onChange(data)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Certificate"
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                          {...props}
                        />
                      )}
                    />

                    <Controller
                      name={`certificates[${i}].issuingDate`}
                      control={control}
                      defaultValue={certificates[i]?.issuingDate || null}
                      render={({ field: { ref, name, ...field } }) => (
                        <DatePicker
                          {...field}
                          inputRef={ref}
                          label="Issuing date"
                          onChange={(e) =>
                            field.onChange(dayjs(e).toISOString())
                          }
                          renderInput={(params) => (
                            <TextField {...params} fullWidth name={name} />
                          )}
                        />
                      )}
                    />

                    <IconButton
                      onClick={() => {
                        if (
                          confirm("Sure you want to remove this certificate?")
                        ) {
                          removeCerts(i);
                        }
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                </React.Fragment>
              ))
            ) : (
              <Typography variant="body">No certificates</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={addCerts}
              sx={{ float: "right", mt: 2 }}
            >
              Add certificate
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Owners</Typography>
            </Divider>
          </Grid>
          {hiddenOwners > 0 ? (
            <Grid item xs={12}>
              <Alert severity="info">
                <AlertTitle>
                  This dog has {hiddenOwners} owners outside of your
                  organization
                </AlertTitle>
                Managers can not edit owners if one or more of them are part of
                a different organization. Please contact the administrator for
                more information.
              </Alert>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <Controller
                  name="owners"
                  render={({ field: { onChange, ...props } }) => (
                    <Autocomplete
                      multiple
                      options={users}
                      getOptionLabel={(option) =>
                        `${option?.name} ${option?.email ? " - " : ""} ${
                          option?.email || " "
                        }`
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          {option.name} {" ("}
                          {option.email}
                          {") "}
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option._id == value?._id
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Owners" />
                      )}
                      onChange={(e, data) => onChange(data)}
                      {...props}
                    />
                  )}
                  onChange={([, data]) => data}
                  defaultValue={initOwners || []}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5">Owners preview</Typography>
                <List>
                  {owners &&
                    owners?.map((owner, i) => {
                      return (
                        owner && (
                          <ListItem
                            key={owner._id}
                            secondaryAction={owner.breederId || ""}
                          >
                            <ListItemText
                              primary={`${owner.name}`}
                              secondary={`${owner?.email || ""} 
                            (${owner?._id || ""})`}
                            />
                          </ListItem>
                        )
                      );
                    })}
                </List>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Breeder</Typography>
            </Divider>
          </Grid>
          {breeder === "hidden" ? (
            <Grid item xs={12}>
              <Alert severity="info">
                <AlertTitle>
                  This dog has breeder that is outside of your organization
                </AlertTitle>
                Managers can not edit breeder information if the breeder is part
                of a different organization. Please contact the administrator
                for more information.
              </Alert>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <Controller
                  name="breeder"
                  render={({ field: { onChange, ...props } }) => (
                    <Autocomplete
                      fullWidth
                      options={users}
                      getOptionLabel={(option) =>
                        `${option.name} (${option.email})`
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          {option.name} {" ("}
                          {option.email}
                          {") "}
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option._id == value._id
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Breeder" />
                      )}
                      onChange={(e, data) => onChange(data)}
                      {...props}
                    />
                  )}
                  onChange={([, data]) => data}
                  defaultValue={initBreeder}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5">Breeder preview</Typography>

                <List>
                  {breeder && (
                    <ListItem secondaryAction={breeder?.regNumber}>
                      <ListItemText
                        primary={`${breeder?.name}`}
                        secondary={`${breeder?.email || ""} (${breeder?._id})`}
                      />
                    </ListItem>
                  )}
                </List>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Family</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="sire"
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  options={maleDogs}
                  getOptionLabel={(option) =>
                    `${option.name} ${option.regNumber || ""}`
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.name} {option.regNumber || ""}
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option._id == value._id
                  }
                  autoComplete
                  noOptionsText={"no data"}
                  renderInput={(params) => (
                    <TextField {...params} label="Sire" />
                  )}
                  onChange={(e, data) => onChange(data)}
                  {...props}
                />
              )}
              onChange={([, data]) => data}
              defaultValue={selectedSire || null}
              control={control}
            />
            <List sx={{ mt: 2 }}>
              <ListItem>
                <Typography variant="h5">Sire preview</Typography>
              </ListItem>
              {formdata.sire && (
                <ListItem>
                  <ListItemButton
                    href={`/admin/dog/${formdata.sire._id}`}
                    target="_blank"
                  >
                    <ListItemIcon>
                      <Launch />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${formdata.sire.name} ${
                        formdata.sire.regNumber || ""
                      }`}
                      secondary={`${formdata.sire._id}`}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="dam"
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  options={femaleDogs}
                  getOptionLabel={(option) =>
                    `${option.name} ${option.regNumber || ""}`
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.name} {option.regNumber || ""}
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option._id == value._id
                  }
                  autoComplete
                  noOptionsText={"no data"}
                  renderInput={(params) => (
                    <TextField {...params} label="Dam" />
                  )}
                  onChange={(e, data) => onChange(data)}
                  {...props}
                />
              )}
              onChange={([, data]) => data}
              defaultValue={selectedDam || null}
              control={control}
            />

            <List sx={{ mt: 2 }}>
              <ListItem>
                <Typography variant="h5">Dam preview</Typography>
              </ListItem>
              {formdata.dam && (
                <ListItem>
                  <ListItemButton
                    href={`/admin/dog/${formdata.dam._id}`}
                    target="_blank"
                  >
                    <ListItemIcon>
                      <Launch />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${formdata.dam.name} ${
                        formdata.dam.regNumber || ""
                      }`}
                      secondary={`${formdata.dam._id}`}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Appearance</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Color</InputLabel>
              <Controller
                name="color"
                control={control}
                defaultValue={initialDog?.color || []}
                render={({ field: { ref, name, ...field } }) => (
                  <Select {...field} fullWidth multiple label="Color">
                    <ListSubheader>Colors (select one)</ListSubheader>
                    {kruValues
                      .filter((i) => i.category === "coat.colors")
                      .map((color) => (
                        <MenuItem key={color._id} value={color._id}>
                          {color.en.name}
                        </MenuItem>
                      ))}
                    <ListSubheader>Markings (select multiple)</ListSubheader>
                    {kruValues
                      .filter((i) => i.category === "coat.markings")
                      .map((color) => (
                        <MenuItem key={color._id} value={color._id}>
                          {color.en.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Coat</InputLabel>
              <Controller
                name="coat"
                control={control}
                defaultValue={initialDog?.coat || []}
                render={({ field: { ref, name, ...field } }) => (
                  <Select {...field} fullWidth multiple label="Coat">
                    <ListSubheader>Coat types (select one)</ListSubheader>
                    {kruValues
                      .filter((i) => i.category === "coat.types")
                      .map((color) => (
                        <MenuItem key={color._id} value={color._id}>
                          {color.en.name}
                        </MenuItem>
                      ))}
                    <ListSubheader>
                      Coat qualities (select multiple)
                    </ListSubheader>
                    {kruValues
                      .filter((i) => i.category === "coat.qualities")
                      .map((color) => (
                        <MenuItem key={color._id} value={color._id}>
                          {color.en.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="height"
              control={control}
              defaultValue={initialDog?.height || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Height at withers (cm)"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="weight"
              control={control}
              defaultValue={initialDog?.weight || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Weight (kg)"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Genetic Breed Test</Typography>
            </Divider>
          </Grid>
          {initialDog?.breedtest ? (
            <>
              <Grid item xs={12} md={6}>
                <Typography variant="caption" sx={{ display: "block" }}>
                  Source
                </Typography>
                <Typography variant="body">
                  {initialDog?.breedtest?.source}
                </Typography>
                <Typography variant="caption" sx={{ display: "block", mt: 1 }}>
                  Date
                </Typography>
                <Typography variant="body">
                  {initialDog?.breedtest?.date &&
                    dayjs(initialDog?.breedtest?.date).format("DD.MM.YYYY")}
                </Typography>

                <Typography variant="caption" sx={{ display: "block", mt: 1 }}>
                  Subject
                </Typography>
                <Typography variant="body">
                  {initialDog?.breedtest?.subject}
                </Typography>

                <Typography variant="caption" sx={{ display: "block", mt: 1 }}>
                  Additional info
                </Typography>
                <Typography variant="body">
                  {initialDog?.breedtest?.additionalInfo}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <List>
                  <ListItem>
                    <Typography variant="h5">Breeds</Typography>
                  </ListItem>
                  {initialDog?.breedtest?.breeds?.map((breed, i) => {
                    return (
                      <ListItem key={i}>
                        <Typography variant="body" sx={{ mr: 2 }}>
                          {breed?.name}
                        </Typography>
                        <Typography variant="body">
                          {breed.percentage} {"%"}
                        </Typography>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Typography variant="body">
                {"Dog doesn't have breed test"}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack alignItems="flex-start">
              <Button variant="outlined" onClick={handleModalOpen}>
                Edit breed test
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <FormModal open={modalOpen} onClose={handleBreedtestModalClose}>
              <BreedTestComponent
                initialDog={initialDog}
                formType={formType}
                handleModalClose={handleBreedtestModalClose}
              />
            </FormModal>
          </Grid>

          {action === "edit" && (
            <>
              <Grid item xs={12}>
                <Divider textAlign="left" sx={{ mb: 2 }}>
                  <Typography variant="h5">Profile picture</Typography>
                </Divider>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper component="img" src={imgUrl} sx={{ width: "100%" }} />
                <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
                  <Button variant="outlined" component="label">
                    Upload new picture
                    <input
                      hidden
                      accept="image/*"
                      name="profilePicture"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                  <Typography variant="caption">
                    NOTE: Uploading new picture will refresh the page. Make sure
                    you have saved other changes.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="photoTakenBy"
                  control={control}
                  defaultValue={initialDog?.photoTakenBy || ""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Dog's profile picture taken by"
                      helperText="The person's name who has the copyright of the image"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider textAlign="left" sx={{ my: 2 }}>
                  <Typography variant="h5">Attachments</Typography>
                </Divider>
                <AttachmentList parentDoc={initialDog} />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <JsonPreviewWidget formdata={formdata} />
          </Grid>
          <Grid item xs={12}>
            <FormButtons
              action={action}
              handleDelete={handleDelete}
              isSubmitting={isSubmitting}
              isDirty={isDirty}
              errors={errors}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default DogForm;
