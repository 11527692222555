import React from "react";

// Routes
import { updateTempLitter } from "../../../api/adminRoutes";

// Components
import LitterForm from "../forms/litterForm";

import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { snackBarState } from "../../../recoil/globalStates";
import { selectedDocByIdState } from "../../../recoil/adminStates";
import { AdminNotFound } from "../../../components/adminDashboardComponents/adminNotFound";

function AdminTempLitterView() {
  const { t } = useTranslation();
  const setSnackbarState = useSetRecoilState(snackBarState);

  const litter = useRecoilValue(selectedDocByIdState("tempLitter"));

  const handleSubmit = (accessToken, newLitter, id) => {
    updateTempLitter(id, newLitter, {
      accessToken,
      options: { new: true },
    }).then((res) => {
      if (res.error) {
        setSnackbarState({
          message: res.message,
          severity: "error",
          open: true,
        });
      } else {
        setSnackbarState({
          message: t("updateSuccess", { docType: "TempLitter" }),
          severity: "success",
          open: true,
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
    });
  };

  return litter ? (
    <LitterForm
      litter={litter}
      formType="tempLitter"
      action="edit"
      emitHandleSubmit={handleSubmit}
    />
  ) : (
    <AdminNotFound docType="Litter" />
  );
}

export default AdminTempLitterView;
