import React from "react";
// get the discriminator of the health test result by subject id
export function GetHealthtestDiscriminator(subjectId) {
  switch (subjectId) {
    case "healthtest.subjects.hdfci":
      return "hdFci";
    case "healthtest.subjects.hdofa":
      return "hdOfa";
    case "healthtest.subjects.ediewg":
    case "healthtest.subjects.edofa":
    case "healthtest.subjects.incfkc":
    case "healthtest.subjects.plputnam":
    case "healthtest.subjects.plofa":
      return "twoSidedNumerical";
    case "healthtest.subjects.spfkc":
    case "healthtest.subjects.iddfkc":
    case "healthtest.subjects.vafkc":
    case "healthtest.subjects.ltvfkc":
      return "singleNumerical";
    case "healthtest.subjects.ocfkc":
      return "ocFkc";
    case "healthtest.subjects.hau":
      return "hau";
    case "healthtest.subjects.testicles":
      return "testicles";
    case "healthtest.subjects.idioepilepsy":
      return "diagnosis";
    case "healthtest.subjects.ecvo":
      return "eyesEcvo";
    default:
      return false;
  }
}

export function isFormatSidesOrGrade(resultType) {
  // if discriminator has two sides (left, right) return "twoSides"
  // if discriminator has grade return "grade"
  // if discriminator is ECVO eye examination result return "ecvo"

  switch (resultType) {
    case "twoSidedNumerical":
    case "hdFci":
    case "hdOfa":
    case "ocFkc":
      return "twoSides";
    case "singleNumerical":
    case "hau":
    case "testicles":
    case "diagnosis":
      return "grade";
    case "eyesEcvo":
      return "ecvo";
    default:
      false;
  }
}

export function getEcvoResultsForUI(result) {
  // turn the result object into array of arrays (Object.entries)
  const modifiableResult = Object.entries(result);

  // filter out _id and type
  const cleanedResults = modifiableResult.filter(
    (result) => result[0] !== "_id" && result[0] !== "type"
  );

  return cleanedResults;
}
