import React from "react";
import { ArrowBack } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";

export default function FormHeader({
  action,
  formType,
  data,
  handleModalClose,
}) {
  return (
    <>
      {action === "edit" && (
        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          justifyContent={{ xs: "center", md: "space-between" }}
          alignItems={"center"}
          mb={2}
        >
          <Button
            size="small"
            color="gray"
            href={`/admin/all${formType}s`}
            startIcon={<ArrowBack />}
          >
            Back
          </Button>
        </Stack>
      )}
      {action === "add" && (
        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          justifyContent={{ xs: "center", md: "flex-end" }}
          alignItems={"center"}
          mb={2}
        >
          <Button onClick={handleModalClose}>Close</Button>
        </Stack>
      )}

      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "center", md: "space-between" }}
        alignItems={"center"}
      >
        <Typography variant="h2">
          {action.toUpperCase()} {formType.toUpperCase()}{" "}
        </Typography>
        <Typography variant="caption">
          {action === "edit" && data?._id}
        </Typography>
      </Stack>
      {action === "edit" && (
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent={{ xs: "center", md: "flex-start" }}
          alignItems={"center"}
          spacing={{ xs: 0, md: 2 }}
          my={1}
        >
          <Typography variant="caption">
            Created{" "}
            {data?.createdAt && dayjs(data?.createdAt).format("DD.MM.YYYY")}
          </Typography>

          <Typography variant="caption">
            Updated{" "}
            {data?.updatedAt && dayjs(data?.updatedAt).format("DD.MM.YYYY")}
          </Typography>
        </Stack>
      )}
    </>
  );
}
