import { selector, selectorFamily } from "recoil";
import {
  getDogs,
  getLitters,
  getAllOrganizations,
  getBreederById,
  getBreedersWithLitters,
  getCertificateById,
  getDbStats,
  getDogById,
  getDogByRegNumber,
  getLitterById,
  getLitterBySiblingId,
  getLittersByBreederId,
  getLittersByParentId,
  getOrganizationById,
  getResultproviders,
  getGeneticTestTypes,
  getResultProviderById,
  getGeneticTestTypeById,
} from "../api/publicRoutes";
import { kruValuesState } from "./globalStates";

// STATISTICS FOR FRONT PAGE
export const dbStatsState = selector({
  key: "dbStatsState",
  get: async () => {
    const response = await getDbStats();
    if (response.error) {
      throw response;
    }
    return response.stats;
  },
});

// NEWEST DOGS FOR FRONT PAGE
export const newestDogsState = selector({
  key: "newestDogsState",
  get: async () => {
    const options = { limit: 10, sort: { createdAt: -1 } };
    const response = await getDogs({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// NEWEST LITTERS FOR FRONT PAGE
export const newestLittersState = selector({
  key: "newestLittersState",
  get: async () => {
    const options = { limit: 10, sort: { createdAt: -1 } };
    const response = await getLitters({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// DOG BY ID OR REGNUMBER
export const currentDogState = selectorFamily({
  key: "currentDogState",
  get: (id) => async () => {
    const reqFunc = id.length < 12 ? getDogByRegNumber : getDogById;
    const response = await reqFunc(id);
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// BREEDER BY ID
export const breederState = selectorFamily({
  key: "breederState",
  get: (id) => async () => {
    const options = { populate: "certificates.certificate" };
    const response = await getBreederById(id, { options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// LITTER BY ID
export const litterState = selectorFamily({
  key: "litterState",
  get: (id) => async () => {
    const response = await getLitterById(id);
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET LITTERS BY BREEDER ID
export const littersByBreederIdState = selectorFamily({
  key: "littersByBreederIdState",
  get: (id) => async () => {
    const response = await getLittersByBreederId(id);
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET PARENTS LITTERS BY DOG ID
export const littersByParentIdState = selectorFamily({
  key: "littersByParentIdState",
  get:
    ({ id, sex }) =>
    async () => {
      const response = await getLittersByParentId(id, sex);
      if (response.error) {
        throw response;
      }
      return response;
    },
});

// GET LITTER BY SIBLINGS ID
export const littersBySiblingIdState = selectorFamily({
  key: "littersBySiblingIdState",
  get: (id) => async () => {
    const response = await getLitterBySiblingId(id);
    if (response.error) {
      throw response;
    }
    return response[0]?.puppies?.filter((puppy) => puppy._id !== id);
  },
});

// GET ALL ORGANIZATIONS
export const organizationsState = selector({
  key: "organizationsState",
  get: async () => {
    const response = await getAllOrganizations();
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// Filter out organizations that have stripe information. Required for registration.
export const stripeOrganizations = selector({
  key: "stripeOrganizationsState",
  get: async ({ get }) => {
    const allOrganizations = get(organizationsState);
    return allOrganizations.filter((o) => !!o?.stripe?.account_id);
  },
});

// GET ORGANIZATION BY ID
export const getOrgByIdState = selectorFamily({
  key: "getOrgByIdState",
  get: (id) => async () => {
    const response = await getOrganizationById(id);
    if (response?.error) {
      throw response;
    }
    return response;
  },
});

// GET CERTIFICATE BY ID
export const getCertByIdState = selectorFamily({
  key: "getCertByIdState",
  get: (id) => async () => {
    const response = await getCertificateById(id);
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET RESULTPROVIDER BY ID
export const getResultProviderByIdState = selectorFamily({
  key: "getResultProviderByIdState",
  get: (id) => async () => {
    const response = await getResultProviderById(id);
    if (response.error) {
      throw response;
    }
    return response;
  },
});
// GET GENETIC TEST TYPE BY ID
export const getGeneticTestTypeByIdState = selectorFamily({
  key: "getGeneticTestTypeByIdState",
  get: (id) => async () => {
    const response = await getGeneticTestTypeById(id);
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// POPULATE ORGANIZATION ARRAY
export const populatedOrgsArrayState = selectorFamily({
  key: "populatedOrgsArrayState",
  get:
    (orgs) =>
    async ({ get }) => {
      if (!orgs) {
        return "";
      }
      return orgs.map((_id) => get(getOrgByIdState(_id)));
    },
});

// POPULATE CERTIFICATES ARRAY
export const populatedCertsArrayState = selectorFamily({
  key: "populatedCertsArrayState",
  get:
    (certs) =>
    async ({ get }) => {
      if (!certs) {
        return "";
      }
      return certs.map((cert) => {
        return {
          ...cert,
          certificate: get(getCertByIdState(cert.certificate)),
        };
      });
    },
});

// POPULATE KRUVALUES ARRAY
export const populatedKruvalueArrayState = selectorFamily({
  key: "populatedKruvalueArrayState",
  get:
    (values) =>
    async ({ get }) => {
      const kruValues = get(kruValuesState);
      if (!values) {
        return "";
      }
      return values.map((value) => kruValues.find((v) => v._id === value));
    },
});

// GET ALL DOGS
export const allDogsState = selector({
  key: "allDogsState",
  get: async () => {
    const options = {
      limit: 0,
      populate: "organizations certificates.certificate",
    };
    const response = await getDogs({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET DOG BY ID
export const dogByIdState = selectorFamily({
  key: "dogByIdState",
  get:
    (id) =>
    ({ get }) => {
      const dogs = get(allDogsState);

      const found = dogs.find((c) => c._id === id);
      if (!found) {
        return null;
      }
      return found;
    },
});

// FILTER DOGS BY GENDER
export const dogsByGenderState = selectorFamily({
  key: "dogsByGenderState",
  get:
    (gender) =>
    ({ get }) => {
      const dogs = get(allDogsState);
      return dogs.filter((dog) => dog.sex === gender);
    },
});

// GET ALL LITTERS
export const allLittersState = selector({
  key: "allLittersState",
  get: async () => {
    const options = { limit: 0 };
    const response = await getLitters({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET ALL BREEDERS THAT HAVE LITTERS
export const allBreedersState = selector({
  key: "allBreedersState",
  get: async () => {
    const options = { populate: "certificates.certificate" };
    const response = await getBreedersWithLitters({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET ALL RESULT PROVIDERS
export const allResultProvidersState = selector({
  key: "allResultProvidersState",
  get: async () => {
    const options = {
      limit: 0,
    };
    const response = await getResultproviders({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

// GET ALL GENETIC TEST TYPES
export const allGeneticTestTypesState = selector({
  key: "allGeneticTestTypesState",
  get: async () => {
    const options = {
      limit: 0,
    };
    const response = await getGeneticTestTypes({ options });
    if (response.error) {
      throw response;
    }
    return response;
  },
});
