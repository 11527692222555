import React from "react";
import { Button, Stack, Typography } from "@mui/material";

export default function AcceptButton({
  action,
  formType,
  registrationStatus,
  handleAccept,
}) {
  return (
    action === "edit" && (
      <>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="flex-end"
          alignItems="center"
          spacing={{ xs: 1, sm: 3 }}
        >
          {formType === "tempdog" ||
            (formType === "tempLitter" && (
              <>
                <Typography variant="body">
                  <b>Registration status</b>
                  <br /> {registrationStatus || "-"}
                </Typography>
                <Button variant="contained" onClick={handleAccept}>
                  Accept registration
                </Button>
              </>
            ))}
        </Stack>
      </>
    )
  );
}
