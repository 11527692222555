import React from "react";

import { useRecoilValue } from "recoil";
import { selectedDocByIdState } from "../../../recoil/adminStates";
import { AdminNotFound } from "../../../components/adminDashboardComponents/adminNotFound";
import ResultproviderForm from "../forms/resultproviderForm";

function AdminResultproviderView() {
  const provider = useRecoilValue(selectedDocByIdState("resultprovider"));

  return provider ? (
    <ResultproviderForm action="edit" provider={provider} />
  ) : (
    <AdminNotFound docType="Result provider" />
  );
}

export default AdminResultproviderView;
